<template>
    <div style="overflow-x: scroll;">
        <div class="index_new">
            <div class="index_title">
                <div @click="chooseTitle(item.id)" class="title_child" :class="chooseId == item.id ? 'chooseTitle' : ''" :style="{top: item.y+'px', left: item.x+'px'}" v-for="(item,index) in child" :key="index">{{item.name}}</div>
            </div>
            <newScreen1 v-if="chooseId == 1"></newScreen1>
            <newScreen2 v-if="chooseId == 2"></newScreen2>
            <newScreen3 v-if="chooseId == 3"></newScreen3>
            <newScreen4 v-if="chooseId == 4"></newScreen4>
            <newScreen5 v-if="chooseId == 5"></newScreen5>
            <newScreen6 v-if="chooseId == 6"></newScreen6> 
            <newScreen7 v-if="chooseId == 7"></newScreen7>
            <newScreen8 v-if="chooseId == 8"></newScreen8>
            <img v-if="chooseId == 10" class="img" src="@/assets/images/dataScreen/jyzt.png" alt="">
        </div>
    </div>
</template>

<script>
import newScreen1 from './child/newScreen1.vue'
import newScreen2 from './child/newScreen2.vue'
import newScreen3 from './child/newScreen3.vue'
import newScreen4 from './child/newScreen4.vue'
import newScreen5 from './child/newScreen5.vue'
import newScreen6 from './child/newScreen6.vue'
import newScreen7 from './child/newScreen7.vue'
import newScreen8 from './child/newScreen8.vue'
export default {
    components: {
        newScreen1,
        newScreen2,
        newScreen3,
        newScreen4,
        newScreen5,
        newScreen6,
        newScreen7,
        newScreen8,
    },
    data(){
        return {
            child: [
                {
                    id: 1,
                    name: '物联网示范基地',
                    x: 308,
                    y: 41,
                },{
                    id: 2,
                    name: '大漠蔬菜',
                    x: 786,
                    y: 41
                },{
                    id: 3,
                    name: '谷物杂粮',
                    x: 1264,
                    y: 41
                },{
                    id: 4,
                    name: '共享农机',
                    x: 3508,
                    y: 41
                },{
                    id: 5,
                    name: '仓储冷链',
                    x: 3978,
                    y: 41
                },
                // {
                //     id: 10,
                //     name: '经营主体',
                //     x: 4448,
                //     y: 41
                // }
            ],
            chooseId: 1
        }
    },
    mounted () {
        document.title = '榆阳区农业农村局数字农业产业服务平台'        
    },
    methods: {
        chooseTitle(id){
            this.chooseId = id;
        }
    }
}
</script>

<style scoped>
    .index_new {
        width: 5100px;
        height: 1710px;
        background: #0A131F;
        overflow: hidden;
        overflow-x: auto;
    }
    .index_title {
        width: 100%;
        height: 160px;
        position: relative;
        background: url(../../assets/images/newScreen/title.png) no-repeat center center;
        background-size: 5100px 103px;
    }
    .index_title >.title_child {
        position: absolute;
        width: 362px;
        height: 92px;
        text-align: center;
        line-height: 92px;
        color: #fff;
        font-size: 42px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        cursor: pointer;
        
    }
    .index_title >.title_child:hover {
        background: url(../../assets/images/newScreen/choose_title.png) no-repeat center center;
    }
    .index_title >.chooseTitle {
        background: url(../../assets/images/newScreen/choose_title.png) no-repeat center center;

    }
    .img {
        width: 5100px;
        height: 1500px;
    }
</style>