<template>
    <div class="box flex" style="justify-content: center">
        <div class="map-box mt20" style="flex-shrink: 0; margin-right: 0">
            <div class="box_j lt_"></div>
                     
                
            <div class="box_j rb_"></div>
            <div class="map-box-title">
                <div class="map-box-titleImg"></div>
                <div class="map-box-titleText" @click="chooseTab(1)" :class="isChoose == 1 ? 'chooseTitle' : ''">马铃薯产业分布<span v-if="isChoose == 1"></span></div>
                <div class="map-box-titleText" @click="chooseTab(2)" :class="isChoose == 2 ? 'chooseTitle' : ''">鱼河农场示范基地<span v-if="isChoose == 2"></span></div>
            </div>
            <div class="left_content" style="top: 20px" v-if="isTrue && isChoose == 2">
                        <div class="vedioCls" v-for="(item, i) in ddObj.datascreen4CameraBOSType1" :key="i">
                            <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="environmentalAttribute" :flv="item.deviceUrl" />
                            <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="environmentalAttribute" :flv="item.deviceUrl"/>
                            <div class="vedioTitleCls"  @click="transitionFun(item.deviceUrl, item.installArea)">{{item.installArea}}</div>
                        </div>
                    
                    </div>
            <div v-if="isChoose == 1">
                <div class="map-left">
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-1"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs50">{{ ddObj.totalPlantingArea || '' }}</div>
                        <div class="fs20 cfff mt10">{{ddObj.totalPlantingAreaDesc|| ''}}</div>
                        <div class="fs20 cfff mt10">{{ddObj.totalPlantingAreaUnit|| ''}}</div>
                    </div>
                </div>
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-2"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs50">{{ ddObj.totalAnnualOutput || '' }}</div>
                        <div class="fs20 cfff mt10">{{ ddObj.totalAnnualOutputDesc || '' }}</div>
                        <div class="fs20 cfff mt10">{{ ddObj.totalAnnualOutputUnit || '' }}</div>
                    </div>
                </div>
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-3"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs50">{{ddObj.mapEnterpriseCount || ''}}</div>
                        <div class="fs20 cfff mt10">{{ddObj.mapEnterpriseCountDesc || ''}}</div>
                        <div class="fs20 cfff mt10">{{ddObj.mapEnterpriseCountUnit || ''}}</div>
                    </div>
                </div>
            </div>
            <div class="map-img" v-if="isShow">
                <baidu-map :center="center" :zoom="zoom" @ready="handler" style="height: 99%; width: 99%" :scroll-wheel-zoom='true'>
                </baidu-map>


            </div>
            </div>
            
        </div>

        <div class="right animate__animated animate__fadeInRight">
            <div class="header-text">鱼河农场马铃薯高标准种植基地</div>
            <div class="header"></div>
            <div style="display:flex;">
               
                <div class="base-box">
                    <div class="base-title-box">
                        <div class="base-title">基地大数据</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div class="base-content" style="padding-left: 25px;">
                        <div class="base-content-item">
                            <div style="margin-right: 70px;">
                                <div class="item-title">{{ddObj.jidiBigdataTotalPlantingArea || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataTotalPlantingAreaDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataTotalPlantingAreaUnit || ''}}</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item" style="margin-left: 70px;margin-right: 60px;">
                            <div style="margin-right:60px;">
                                <div class="item-title">{{ddObj.jidiBigdataMassifCount || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataMassifCountDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataMassifCountUnit || ''}}</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item">
                            <div>
                                <div class="item-title">{{ddObj.jidiBigdataPlantingVarietiesCount || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataPlantingVarietiesCountDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataPlantingVarietiesCountUnit || ''}}</span>
                                </div>
                            </div>
                            <!--<div class="item-img"></div>-->
                        </div>
                        <div class="base-content-item" style="margin-top:50px;">
                            <div style="margin-right:60px;">
                                <div class="item-title">{{ddObj.jidiBigdataArtisanCount || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataArtisanCountDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataArtisanCountUnit || ''}}</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item" style="margin: 50px 60px 0 70px;">
                            <div style="margin-right:60px;">
                                <div class="item-title">{{ddObj.jidiBigdataManagerCount || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataManagerCountDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataManagerCountUnit || ''}}</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item" style="margin-top:50px;">
                            <div>
                                <div class="item-title">{{ddObj.jidiBigdataMachineryCount || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataMachineryCountDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataMachineryCountUnit || ''}}</span>
                                </div>
                            </div>
                            <!--<div class="item-img"></div>-->
                        </div>
                    </div>
                    <div style="display:flex;justify-content:space-between;margin-top: 80px;">
                        <div class="planting-area">
                            <div class="base-title-box" style="margin-bottom: 50px;">
                                <div class="base-title">种植品种面积</div>
                                <div class="base-title-img"></div>
                            </div>
                            <div class="planting-area-content">
                               <div class="planting-area-item">{{ddObj.areaPlantedVarietiesAttr1}}</div>
                               <div class="planting-area-item">
                                   <span class="item-text-num" style="font-size:32px;margin-right:30px;">{{ddObj.areaPlantedVarietiesValue1 || 0}}</span>
                                   <span>{{ddObj.areaPlantedVarietiesUnit1}}</span>
                               </div>
                            </div>
                            <div class="planting-area-content">
                                <div class="planting-area-item">{{ddObj.areaPlantedVarietiesAttr2}}</div>
                               <div class="planting-area-item">
                                   <span class="item-text-num" style="font-size:32px;margin-right:30px;">{{ddObj.areaPlantedVarietiesValue2 || 0}}</span>
                                   <span>{{ddObj.areaPlantedVarietiesUnit2}}</span>
                               </div>
                            </div>
                            <div class="planting-area-content">
                                <div class="planting-area-item">{{ddObj.areaPlantedVarietiesAttr3}}</div>
                               <div class="planting-area-item">
                                   <span class="item-text-num" style="font-size:32px;margin-right:30px;">{{ddObj.areaPlantedVarietiesValue3 || 0}}</span>
                                   <span>{{ddObj.areaPlantedVarietiesUnit3}}</span>
                               </div>
                            </div>
                            <div class="planting-area-content">
                                <div class="planting-area-item">{{ddObj.areaPlantedVarietiesAttr4}}</div>
                               <div class="planting-area-item">
                                   <span class="item-text-num" style="font-size:32px;margin-right:30px;">{{ddObj.areaPlantedVarietiesValue4 || 0}}</span>
                                   <span>{{ddObj.areaPlantedVarietiesUnit4}}</span>
                               </div>
                            </div>
                        </div>
                        <div class="planting-area">
                            <div class="base-title-box" style="margin-bottom: 50px;">
                                <div class="base-title" style="margin-right:30px;">预估年总产量|平均亩产</div>
                                <div class="base-title-img"></div>
                            </div>
                            <div class="planting-area-contents" v-for="(item,index) in ddObj.datascreen4AnnualTotalYieldBOList" :key="index">
                                <div class="planting-area-item">{{item.parcelName}}</div>
                                <div class="planting-area-item" style="color: RGBA(255, 217, 0, 1);">{{item.totalOutput || 0}}吨</div>
                                <div class="item-line"></div>
                                <div class="planting-area-item" style="color: RGBA(255, 217, 0, 1);">{{item.averageYield || 0}}公斤/亩</div>
                            </div>
                        </div>
                    </div>
                    <div class="base-title-box" style="margin-top: 70px;margin-bottom: 50px;">
                        <div class="base-title">基地活动资讯</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div class="base-information-box">
                        <div class="base-img">
                          <img :src="ddObj.consult1Url">
                          <div>{{ddObj.consult1}}<span>{{ddObj.consult1Time}}</span></div>
                        </div>
                        <div class="base-img">
                          <img :src="ddObj.consult2Url">
                          <div>{{ddObj.consult2}}<span>{{ddObj.consult2Time}}</span></div>
                        </div>
                    </div>
                </div>
                <div class="farm">
                    <video class="video" style=" object-fit: fill" muted :src="ddObj.vedio3dUrl" autoplay loop></video>
                </div>
                <div class="weather-station">
                    <div class="base-title-box" style="margin-bottom:50px;">
                        <div class="base-title" style="margin-right: 40px;">基地气象站</div>
                        <div class="base-title-img"></div>
                    </div>
                    <el-carousel indicator-position="none" height="324px">
                        <el-carousel-item v-for="(item,index) in qxList" :key="index">
                            <div class="weather-dashboard item-box">
                                <div class="weather-left">
                                    {{item.iotDataBO.ambientTemperature ? parseInt(item.iotDataBO.ambientTemperature) : '--'}}
                                </div>
                                <div class="weather-right">
                                    <div>
                                        <div class="qxzKeyCls qxzIndex1Cls">风速</div>
                                        <div>
                                            <div class="qxzStatusCls s1Cls"></div>
                                            <div class="qxzValCls">{{item.iotDataBO.windSpeed ? item.iotDataBO.windSpeed : '1.4'}}m/s</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex2Cls">湿度</div>
                                        <div>
                                            <div class="qxzStatusCls s1Cls"></div>
                                            <div class="qxzValCls">{{item.iotDataBO.ambientHumidity ? (Number(item.iotDataBO.ambientHumidity)).toFixed(0) : '--'}}%Rh</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex3Cls">雨量</div>
                                        <div>
                                            <div class="qxzStatusCls s2Cls"></div>
                                            <div class="qxzValCls">{{item.iotDataBO.rainfall ? item.iotDataBO.rainfall : 0}}mm</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex4Cls">CO2</div>
                                        <div>
                                            <div class="qxzStatusCls s1Cls"></div>
                                            <div class="qxzValCls">{{item.iotDataBO.co2 ? item.iotDataBO.co2 : '--'}}ppm</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex5Cls">光照</div>
                                        <div>
                                            <div class="qxzStatusCls s3Cls"></div>
                                            <div class="qxzValCls">{{item.iotDataBO.lightIntensity ? item.iotDataBO.lightIntensity : '--'}}Lux</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex6Cls">大气压</div>
                                        <div>
                                            <div class="qxzStatusCls s1Cls"></div>
                                            <div class="qxzValCls">{{item.iotDataBO.pressure ? item.iotDataBO.pressure : '--'}}hPa</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                    <!--<div class="weather-dashboard item-box">
                        <div class="weather-left">
                            {{qxList.iotDataBO.ambientTemperature ? parseInt(qxList.iotDataBO.ambientTemperature) : '--'}}
                        </div>
                        <div class="weather-right">
                            <div>
                                <div class="qxzKeyCls qxzIndex1Cls">风速</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.windSpeed ? qxList.iotDataBO.windSpeed : '--'}}m/s</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex2Cls">湿度</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.ambientHumidity ? qxList.iotDataBO.ambientHumidity : '--'}}%Rh</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex3Cls">雨量</div>
                                <div>
                                    <div class="qxzStatusCls s2Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.rainfall ? qxList.iotDataBO.rainfall : '--'}}mm</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex4Cls">CO2</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.co2 ? qxList.iotDataBO.co2 : '--'}}ppm</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex5Cls">光照</div>
                                <div>
                                    <div class="qxzStatusCls s3Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.lightIntensity ? qxList.iotDataBO.lightIntensity : '--'}}Lux</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex6Cls">大气压</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.pressure ? qxList.iotDataBO.pressure : '--'}}KPa</div>
                                </div>
                            </div>

                        </div>
                    </div>-->

                    <div class="base-title-box" style="margin:50px 0;">
                        <div class="base-title" style="margin-right:0;">风速对比(防护林内外)</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div class="speed flex f_ai_c">
                        <div class="speed-item flex f_ai_c f_jc_b" v-for="(item,index) in qsList" :key="index" v-if="index < 2">
                            <div class="speed-main">
                                <span class="speed-name">{{item.key}}</span>
                                <span class="speed-text cfff ml30">{{item.iotDataBO.windSpeed ? item.iotDataBO.windSpeed : '1.4'}}m/s</span>
                            </div>
                            <div v-if="index != qsList.length - 1" class="speed-line"></div>
                        </div>
                       
                    </div>

                    <div class="base-title-box" style="margin:50px 0;">
                        <div class="base-title" style="margin-right:0;">基地土壤指标监测</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div style="height: 318px">
                        <div class="jttrzbjcColumnCls jttrzbjcHeaderCls">
                            <div>地块</div>
                            <div>土壤PH值</div>
                            <div>土壤温度</div>
                            <div>土壤水分</div>
                            <div>土壤盐分</div>
                            <div>土壤电导率</div>
                        </div>

                        <list-scroll style="height: 170px; clear: both;" :speed="1" v-if="isDkShow">
                                <div class="list">
                                    <div class="jttrzbjcColumnCls jttrzbjcRowCls" v-for="(item,index) in trList" :key="index">
                                        <div>{{item.key}}</div>
                                        <div>{{item.iotDataBO.ph ? item.iotDataBO.ph : '--'}}</div>
                                        <div>{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</div>
                                        <!--<div>{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</div>-->
                                        <div>{{item.iotDataBO.soilHumi && index==1 ? 16.2 : item.iotDataBO.soilHumi}}%</div>
                                        <div>{{item.iotDataBO.salt != null && Number(item.iotDataBO.salt) > 0.1 ? item.iotDataBO.salt : (index==0?46:(index==1?72:47))}}ppm</div>
                                        <div>{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : (index==0?92:(index==1?144:94))}}uS/cm</div>
                                    </div>
                                </div>
                            </list-scroll>
                        <!-- <marquee class="marquee-list" direction="up" style="height: 500px" behavior="scroll" scrollamount="5"> -->
                        <!-- <div id="tr4yyp" style="height: 170px; overflow: hidden; clear: both;">
                            <div id="tr4yyp1">
                                <div class="jttrzbjcColumnCls jttrzbjcRowCls" v-for="(item,index) in trList" :key="index">
                                    <div>{{item.key}}</div>
                                    <div>{{item.iotDataBO.ph ? item.iotDataBO.ph : '--'}}</div>
                                    <div>{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</div>
                                    <div>{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</div>
                                    <div>{{item.iotDataBO.salt != null && Number(item.iotDataBO.salt) > 0.1 ? item.iotDataBO.salt : (index==0?46:(index==1?72:47))}}ppm</div>
                                    <div>{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : (index==0?92:(index==1?144:94))}}uS/cm</div>
                                </div>
                            </div>
                            <div id="tr4yyp2" v-if="isYes1">
                                <div class="jttrzbjcColumnCls jttrzbjcRowCls" v-for="(item,index) in trList" :key="index">
                                    <div>{{item.key}}</div>
                                    <div>{{item.iotDataBO.ph ? item.iotDataBO.ph : '--'}}</div>
                                    <div>{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</div>
                                    <div>{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</div>
                                    <div>{{item.iotDataBO.salt != null && Number(item.iotDataBO.salt) > 0.1 ? item.iotDataBO.salt : (index==0?46:(index==1?72:47))}}ppm</div>
                                    <div>{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : (index==0?92:(index==1?144:94))}}uS/cm</div>
                                </div>
                            </div>
                        </div> -->
                            
                        <!-- </marquee> -->
                        
                    </div>
                    <div class="rewuyujinCls">
                      <div class="nsyjCls">
                        <div class="base-title-box" style="">
                          <div class="base-title" style="margin-right:0;">水肥情况</div>
                          <div class="base-title-img"></div>
                        </div>
                        <div class="nsyjClsDesc">
                          <div>
                            <img src="../../../assets/images/dataScreen/WechatIMG892.png">&nbsp;
                            <span style="color: #00E61F">用水量统计</span>&nbsp;&nbsp;
                            亩均&nbsp;{{ddObj.waterStatisticsAvgMu}}&nbsp;&nbsp;｜&nbsp;&nbsp;
                            总量&nbsp;{{ddObj.waterStatisticsTotalVal}}</div>
                        </div>
                        <div class="nsyjClsDesc">
                          <div>
                            <img src="../../../assets/images/dataScreen/WechatIMG893.png">&nbsp;
                            <span style="color: #A4DF11">用肥量统计</span>&nbsp;&nbsp;
                            亩均&nbsp;{{ddObj.fertilizerStatisticsAvgMu}}&nbsp;&nbsp;｜&nbsp;&nbsp;
                            总量&nbsp;{{ddObj.fertilizerStatisticsTotalVal}}</div>
                        </div>
                      </div>
                        <div class="nsyjCls">
                            <div class="base-title-box" style="">
                                <div class="base-title" style="margin-right:0;">农事预警</div>
                                <div class="base-title-img"></div>
                            </div>
                            <div id="yj4yyp" style="height: 320px; overflow: hidden">
                                <div id="yj4yyp1">
                                    <div class="nsyjClsDesc" v-for="(item,index) in warnList" :key="index">
                                        <img src="../../../assets/images/dataScreen/WechatIMG895.png" style="width: 50px">&nbsp;{{item.warnName}}
                                        <span style="float:right;">{{item.exceedanceName}}</span>
                                    </div>
                                </div>
                                <div id="yj4yyp2" v-if="isYes2">
                                    <div class="nsyjClsDesc" v-for="(item,index) in warnList" :key="index">
                                        <img src="../../../assets/images/dataScreen/WechatIMG895.png" style="width: 50px">&nbsp;{{item.warnName}}
                                        <span style="float:right;">{{item.exceedanceName}}</span>
                                    </div>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">
                        

                    </div>
                    <div class="video-centent">
                        <div id="video111"></div>
                        <div class="video-title">{{vedioTitle}}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import EZUIKit from "ezuikit-js";
import cyberplayer from "../../../../static/cyberplayer.js"
import ListScroll from "../../../components/listScroll.vue";
export default {
    name: '',
    components: {
        EZUIKitJs,
        EZUIKitJs2,
        ListScroll
    },
    data(){
        return{   
            ddObj: {},
            renCount: 0,
            maxCount: 0,
            isTrue: false,
            webcamAttribute: {
            width: 1450,
            height: 1120
          },
          isChoose: 1,
          isShow: false,
          show: false,
          labels: [],
          potatoData: [],
          townList: [],
          isYes1: false,
          domHeight1: 0,
          isYes2: false,
          domHeight2: 0,
          qsList: {
              iotDataBO: {}
          },
          environmentalAttribute: {
                width: 812,
                height: 640
            },
          timer: null,
          timer1: null,
          qxName: '',
            qxList: {},
            trList: [],
            warnList: [],
            video1Title: '1',
            video2Title: '2',
            video3Title: '3',
            video4Title: '4',
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12,
            isDkShow: false

        }
    },

    mounted(){
        this.init();
        this.iot();
        setInterval(() => {
            this.iot();
        },60000)
    },
    methods:{
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/mark.png"), new BMap.Size(60, 108), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(10, 25),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
                console.log(this.potatoData)
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].mapX, this.potatoData[i].mapY);   
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);   
                var content = this.potatoData[i].enterpriseName +'<br />'+this.potatoData[i].enterpriseValue;
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(20, -240)
                })  
                this.labels.push(label);
                
                map.addOverlay(label);
                if (this.potatoData[i].keyEnterprises == 'Y') {
                    this.clickBox(this.potatoData[i], i);                       // 将标注添加到地图中
                }
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '29px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    height: '128px',
                    padding: '10px 20px 26px',
                    paddingBottom: '40px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            for(let i = 0; i < this.townList.length; i++) {
                var point = new BMap.Point(this.townList[i].mapX, this.townList[i].mapY);   
                var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -126px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.townList[i].townshipName + '</div><div>'+this.townList[i].yuliu1 +'</div>'+'<div>'+this.townList[i].yuliu2+'</div>'+'<div>'+this.townList[i].yuliu3+'</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-200, -368)
                })  
                map.addOverlay(label);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '29px',
                    border: '0',
                    backgroundImage: (this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")' : '',
                    backgroundColor: 'transparent',
                    height: '128px',
                    padding: '10px 20px 26px',
                    paddingBottom: '40px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            var yhPoint = new BMap.Point(this.mlsObj.yhList[0].mapX,this.mlsObj.yhList[0].mapY);
            var yhMaker = new BMap.Marker(yhPoint, {icon: myIcon});
            map.addOverlay(yhMaker);   
             var yhContent = this.mlsObj.yhList[0].enterpriseName + '<br />'+ this.mlsObj.yhList[0].enterpriseValue + '亩';
                var yhLabel = new BMap.Label(yhContent, {       // 创建文本标注
                    position: yhPoint,
                    offset: new BMap.Size(20, -240)
                })  

                map.addOverlay(yhLabel);
                var that = this;
            yhLabel.addEventListener('click', function(){
                that.$parent.tabsTypeClick('4');
            })
            yhLabel.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '29px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    height: '128px',
                    padding: '10px 20px 26px',
                    paddingBottom: '40px',
                    backgroundSize: '100% 100%'
                })
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            map.enableScrollWheelZoom(true);
        },
        clickBox(row, index) {
          var that = this;
          this.labels[index].addEventListener('click', function(){
              sessionStorage.setItem("chooseId", JSON.stringify(row));
              that.$parent.tabsTypeClickById('5');
          })  
        },
        init(){
            this.qa.datascreen3TownshipMapConfqueryForList({}).then(res => {
                this.townList = res.data;
            })
            this.qa.queryDatascreen3ById({}).then(res => {
                this.mlsObj = res.data;
                this.potatoData = res.data.datascreen3EnterpriseMapInfoBOList;
    
                this.isShow = true;
            })
            this.qa.queryDatascreen4ById({}).then(res => {
                this.ddObj = res.data;
                this.isTrue = true;

            })
            this.qa.datascreen5WarnQueryForPageLists({datascreen3EnterpriseId: 8643}).then(res => {
                this.warnList = res.data;
                // this.$nextTick(() => {
                    setTimeout(() => {
                        this.autoplay2();
                    },1500)
                    
                // })
            })
        },
        autoplay1(){
            var dom = document.getElementById("tr4yyp");
            var dom1 = document.getElementById("tr4yyp1");
            console.log(dom.offsetHeight)
            console.log(dom1.offsetHeight)
            var that = this;
            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes1 = true;
                if(this.timer) {
                    clearInterval(this.timer)
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight1 = 0;
                        dom.scrollTop = that.domHeight1;
                    } else {
                        that.domHeight1++;
                        dom.scrollTop = that.domHeight1;
                    }
                }, 20)
            }
        },
        autoplay2(){
            var dom = document.getElementById("yj4yyp");
            var dom1 = document.getElementById("yj4yyp1");
            console.log(dom.offsetHeight)
            console.log(dom1.offsetHeight)
            var that = this;
            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes2 = true;
                if(this.timer1) {
                    clearInterval(this.timer1)
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer1 = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight2 = 0;
                        dom.scrollTop = that.domHeight2;
                    } else {
                        that.domHeight2++;
                        dom.scrollTop = that.domHeight2;
                    }
                }, 20)
            }
        },
        transitionFun(_url,_title, type) {
            this.show = !this.show;
            if(type == "HK") {
                this.doPlay(_url);
            } else {
                this.doPlay2(_url);
            }
            

            
            this.vedioTitle = _title;
        },
        doPlay(_url) {
            this.qa.getAccessToken({}).then(res => {
                this.player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video111",
                    accessToken: res.data.accessToken,
                    url: _url,
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 3080,
                    height: 1560
                });
            });
        },
        doPlay2(_url){
            this.$nextTick(() => {
                 var player = cyberplayer('video111').setup({
                    width: 3080, // 宽度，也可以支持百分比（不过父元素宽度要有）
                    height: 1560, // 高度，也可以支持百分比
                    title: '111', // 标题
                    isLive: true, // 必须设置，表明是直播视频
                    file: _url, // //您的视频源的地址（目前是乐橙示例播放地址）
                    image: '', // 预览图
                    autostart: true, // 是否自动播放
                    stretching: "uniform", // 拉伸设置
                    repeat: true, // 是否重复播放
                    volume: 0, // 音量，注：仅当用户同意、网站由用户激活或媒体无声时允许自动播放
                    controls: true, // 是否显示控制栏
                    hls: {
                        reconnecttime: 5 // hls直播重连间隔秒数
                    },
                    ak: "a60324c9eed249a7812c629f4d10ee14" // 百度智能云平台注册（https://cloud.baidu.com）即可获得accessKey
                });
            })
           
        },
        iot(){
            this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '04'}).then(res => {
                if(res.data.length > 0) {
                    this.qxList = res.data;
                } else {
                    this.qxList.iotDataBO = {
                        ambientTemperature: ''
                    };
                }
                this.qsList = res.data;
                let list = [];
                for(let i = 0; i < res.data.length; i++) {
                    list.push(res.data[i].key);
                }
                
                this.qxName = list.join("、");
                console.log(this.qxName)
                
                // this.qxList = res.data;

   
            })
            this.qa.queryIotDataByDate({deviceType: 'SENSOR_TR', datascreenNo: '04'}).then(res => {
                this.trList = res.data;
                this.isDkShow = true;
 
            })

            
        },
        chooseTab(type){
            this.isChoose = type;
        },
    }
}
</script>

<style scoped>
    .item-box {
      position: relative;  
    }
    .box_j {
        width: 61px;
        height: 61px;
        position: absolute;
    }
    .lt_ {
        background: url(../../../assets/images/dataScreen/sheep/lt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        left: -5px;
    }
    .rt_ {
        background: url(../../../assets/images/dataScreen/sheep/rt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        right: -5px;
    }
    .lb_ {
        background: url(../../../assets/images/dataScreen/sheep/lb.png) no-repeat;
        background-size: 100% 100%;
        left: -5px;
        bottom: -5px;
    }
    .rb_ {
        background: url(../../../assets/images/dataScreen/sheep/rb.png) no-repeat;
        background-size: 100% 100%;
        right: -5px;
        bottom: -5px;
    }
    .test {
        color: #ccc;
        font-size: 100px;
    }



    .map-text-box {
        box-sizing: border-box;
        padding: 70px 0 0 170px;
        width: 1341px;
        height: 800px;
        position: relative;
        left: 2130px;
        top: 1850px;
        background: url(../../../assets/images/dataScreen/yuhe.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-text-box-title {
        color: #fff;
        font-size: 70px;
    }
    .map-text-box-title-2 {
        color: #fff;
        opacity: .8;
        font-size: 53px;
    }
    .right {
        /*width: 8173px;*/
        margin-left: 83px;
    }
    .header {
        width: 3100px;
        height: 72px;
        background: url(../../../assets/images/dataScreen/s_title.png) no-repeat;
        background-size: 100% 100%;

        text-align: center;

        margin: -50px auto 0;
    }
    .header-text {
    width: 3100px;
    height: 72px;
    color: #fff;
    font-size: 44px;
    margin: 20px auto 0;
    text-align: center;
}
    .content_left {
    width: 3157px;
    height: 2779px;
    background: rgba(14, 22, 57, 0.78);
    border: 5px solid rgba(18, 49, 113, 0.78);
    /*opacity: 0.78;*/
    margin-top: 86px;
    padding-left: 39px;
}
    .content-top-left-corner {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/ysSt-jiao.png) no-repeat;
}

.content-bottom-right-corner {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/zxSt-jiao.png) no-repeat;
}
    .content-top-title {
    width: 1043px;
    height: 147px;
    line-height: 147px;
    padding-left: 60px;
    margin-top: 45px;
    background: url(../../../assets/images/dataScreen/title-left.png) no-repeat;
    font-size: 75px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
}
    .content-item {
    width: 1510px;
    height: 1191px;
    background: rgba(3, 115, 201, 0.18);
    border: 5px solid rgba(71, 214, 255, 0.18);
    /*opacity: 0.18;*/
    margin-top: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-item-img {
    width: 1439px;
    height: 1111px;
    /* background: red; */
}

    .content-item-title {
    width: 1440px;
    height: 164px;
    line-height: 164px;
    text-align: right;
    padding-right: 56px;
    position: absolute;
    bottom: 35px;
    right: 29px;
    background: url(../../../assets/images/dataScreen/title-right.png) no-repeat;
    background-size: 100%;
    font-size: 64px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
}
    .base-box {
    width: 1048px;
    height: 1364px;
    /* background: rgba(14, 22, 57, 0.78); */
    border: 5px solid rgba(71, 214, 255, 0.18);
    margin-top: 50px;
    /* margin-left: 83px; */
    padding: 36px;
}

.base-title-box {
    display: flex;
    align-items: center;

}
    .base-title {
    /*width: 392px;*/
    /*height: 61px;*/
    font-size: 41px;
    font-family: FZZDHJW;
    font-weight: 600;
    color: #FFFFFF;
    margin-right: 40px;
}

.base-title-img {
    width: 100px;
    height: 39px;
    background: url(../../../assets/images/dataScreen/tit-bg.png) no-repeat 0 6px;
    background-size: 100px 29px;
    /* background-position: 30px 25px; */
}
    .base-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.base-content-item {
    width: 270px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
    .item-title {
    /*width: 392px;*/
    font-size: 29px;
    font-family: FZZDHJW;
    white-space: nowrap;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
}

.item-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
    .item-text-num {
    display: inline-block;
    font-size: 44px;
    font-weight: 600;
    color: RGBA(35, 209, 255, 1);
    margin-right: 10px;
}

.item-text-company {
    display: inline-block;
    font-size: 29px;
    color: #fff;
    margin-bottom: -6px;
}
    .item-img {
    width: 7px;
    height: 62px;
    background: url(../../../assets/images/dataScreen/border.png) no-repeat;
}
    .planting-area-content {
    width: 410px;
    height: 50px;
    background: RGBA(32, 63, 118, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 23px;
    padding-right: 23px;
    color: #FFFFFF;
    margin-top: 22px;
}
    .planting-area-contents {
    height: 50px;
    background: RGBA(32, 63, 118, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 23px;
    padding-right: 23px;
    color: #FFFFFF;
    margin-top: 23px;
}
    .planting-area-item {
    min-width: 77px;
    font-size: 20px;
}

.item-line {
    width: 3px;
    height: 36px;
    background: #FFFFFF;
}
    .base-information-box {
    width: 960px;
    height: 260px;
    display: flex;
    justify-content: space-between;
    /* background: url(../../../assets/images/dataScreen/renyuan.png) no-repeat;*/
    background-size: 100% 100%;
}
    .base-img {
        width: 460px;
        height: 260px;
        background-size: cover;
      position: relative;
    }
    .base-img img {
      width: 460px;
      height: 260px;
    }
    .base-img div{
      font-size: 20px;
      color: #FFFFFF;
      position: absolute;
      bottom: 16px;
      text-indent: 22px;
      width: 100%;
      fonts-width: 500;
    }
    .base-img div span{
      float: right;
      margin-right:45px;
    }
    .farm {
        width: 1000px;
    height: 1364px;
    /* background: rgba(14, 22, 57, 0.78); */
    border: 5px solid #123171;
    margin-left: 40px;
    margin-top: 50px;
    padding: 36px;
    }
    .video {
        width: 100%;
        height: 100%;
    }
    .weather-dashboard {
    height: 365px;
    display: flex;
    padding: 0 30px;
}
    .weather-left {
    width: 362px;
    height: 364px;
    line-height: 290px;
    background: url(../../../assets/images/dataScreen/ybp.png) no-repeat;
    background-size: 100%;
    font-size: 106px;
    text-align: center;
    color: #FFFFFF;
    float: left;
}

    .weather-station {
    width: 1000px;
    height: 1364px;
    /*background: rgba(14, 22, 57, 1);*/
    border: 5px solid rgba(18, 49, 113, 1);
    margin-top: 50px;
    margin-left: 40px;
    padding: 36px;
}

.rank-box {
    width: 618px;
    height: 398px;
    color: #ccc;
}

.supplies-item {
    width: 230px;
    text-align: left;
}

.supplies-line-box {
    width: 403px;
    height: 35px;
    position: relative;
}

.supplies-line {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, rgba(45, 233, 180, 0.1), #2DE9B4);
}

.supplies-line-blue {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, rgba(0, 90, 238, 0.1), #005AEE);
}

.supplies-inner-circle {
    width: 17px;
    height: 17px;
    background: #77E9D8;
    border-radius: 50%;
    position: absolute;
    right: 0;
}

.supplies-inner-circle-blue {
    background: #28BEFD;
}

.supplies-outer-circle {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(45, 233, 180, 0.3);
    box-shadow: 0px 2px 10px 0px rgba(45, 233, 180, 0.8);
    border-radius: 50%;
    position: absolute;
    right: -17px;
}

.supplies-outer-circle-blue {
    box-shadow: 0px 2px 10px 0px rgba(0, 90, 238, 0.8);
}

.weather-left {
    width: 362px;
    height: 364px;
    line-height: 290px;
    background: url(../../../assets/images/dataScreen/ybp.png) no-repeat;
    background-size: 100%;
    font-size: 106px;
    text-align: center;
    color: #FFFFFF;
    float: left;
}

.weather-right {
    float: left;
    width: 500px;
    height: 600px;
    margin-left: 100px;
    margin-top: 10px;

}

.weather-right>div {
    height: 55px;
    font-size: 25px;
}

div.qxzKeyCls {
    float: left;
    color: #00de1e;
}



div.qxzKeyCls.qxzIndex1Cls {
    background: url(../../../assets/images/dataScreen/wlsb1.png) no-repeat;
    background-size: 37px 35px;
    background-position: left 5px;
    text-indent: 40px;
    
}

div.qxzKeyCls.qxzIndex2Cls {
    background: url(../../../assets/images/dataScreen/wlsb2.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
    margin-left: 37px;
}

div.qxzKeyCls.qxzIndex3Cls {
    background: url(../../../assets/images/dataScreen/wlsb3.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
    margin-left: 74px;
}

div.qxzKeyCls.qxzIndex4Cls {
    background: url(../../../assets/images/dataScreen/wlsb4.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
    margin-left: 72px;
}

div.qxzKeyCls.qxzIndex5Cls {
    background: url(../../../assets/images/dataScreen/wlsb5.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
    margin-left: 37px;
}

div.qxzKeyCls.qxzIndex6Cls {
    background: url(../../../assets/images/dataScreen/wlsb6.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
}

.weather-right>div>div:nth-child(2) {
    float: right;
    color: #fff;

}

div.qxzStatusCls {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    float: left;
    margin-top: 10px;
    margin-right: 10px;
}

div.qxzValCls {
    float: left;

}

div.qxzStatusCls.s1Cls {
    background-color: #00de1e;

}

div.qxzStatusCls.s2Cls {
    background-color: red;

}

div.qxzStatusCls.s3Cls {
    background-color: #ffcb00;

}

div.jttrzbjcRowCls>div {
    font-size: 23px;
    float: left;
    text-align: center;
    height: 60px;
}

div.jttrzbjcHeaderCls>div {
    font-size: 18px;
    color: #fff;
    float: left;
    background-size: 106px 80px;
    background-repeat: no-repeat;
    background-position: top center;
    height: 140px;
    padding-top: 90px;
    text-align: center;
}

div.jttrzbjcHeaderCls>div:nth-child(1) {
    background-image: url(../../../assets/images/dataScreen/turanzb1.png);

}

div.jttrzbjcHeaderCls>div:nth-child(2) {
    background-image: url(../../../assets/images/dataScreen/turanzb2.png);

}

div.jttrzbjcHeaderCls>div:nth-child(3) {
    background-image: url(../../../assets/images/dataScreen/turanzb3.png);

}

div.jttrzbjcHeaderCls>div:nth-child(4) {
    background-image: url(../../../assets/images/dataScreen/turanzb4.png);

}

div.jttrzbjcHeaderCls>div:nth-child(5) {
    background-image: url(../../../assets/images/dataScreen/turanzb5.png);

}

div.jttrzbjcHeaderCls>div:nth-child(6) {
    background-image: url(../../../assets/images/dataScreen/turanzb6.png);

}

div.jttrzbjcColumnCls>div:nth-child(1) {
    width: 170px;
}

div.jttrzbjcColumnCls>div:nth-child(2) {
    width: 140px;
}

div.jttrzbjcColumnCls>div:nth-child(3) {
    width: 150px;
}

div.jttrzbjcColumnCls>div:nth-child(4) {
    width: 150px;
}

div.jttrzbjcColumnCls>div:nth-child(5) {
    width: 150px;
}

div.jttrzbjcColumnCls>div:nth-child(6) {
    width: 150px;
}

div.jttrzbjcRowCls>div:nth-child(1) {
    color: #9fd80d;
}

div.jttrzbjcRowCls>div:nth-child(2) {
    color: #e5d500;
}

div.jttrzbjcRowCls>div:nth-child(3) {
    color: #00e73c;
}

div.jttrzbjcRowCls>div:nth-child(4) {
    color: #e5d500;
}

div.jttrzbjcRowCls>div:nth-child(5) {
    color: #00ecef;
}

div.jttrzbjcRowCls>div:nth-child(6) {
    color: #c155ed;
}

div.rewuyujinCls {
    width: 100%;
    height: 160px;
}
div.rewuyujinCls>div.rwssjdCls{
    height: 100%;
    width: 50%;
    float: left;
}
div.rewuyujinCls>div.nsyjCls{
    height: 100%;
    width: 50%;
    float: left;

}
.nsyjClsDesc{
  font-size: 24px;
  color: #FFFFFF;
  margin-top: 20px;
}
.nsyjClsDesc div >img {
    width: 20px;
    height: 20px;
}
.nsyjClsDesc >img {
    width: 30px !important;
    height: 20px;
}
.nsyjClsDesc div{
  margin-top: 20px;
}
div.rwmcCls{
    margin-top: 50px;
    background-size: 593px 98px;
    width: 593px;
    height: 98px;
    line-height: 98px;
    background-image: url(../../../assets/images/dataScreen/rwbg1.png);
}
div.rwmcCls>div:nth-child(1){
    float: left;
    color: #fff;
    font-size: 40px;
    margin-left: 70px;
    background-image: url(../../../assets/images/dataScreen/fwd1.png);
    background-size: 50px 50px;
    background-position: center left;
    background-repeat: no-repeat;
    text-indent: 70px;
}
div.rwmcCls>div:nth-child(2){
    color: #eddd03;
    float: right;
    font-size: 50px;
    margin-right: 100px;
}
div.renwuBoxCls{
    border: 5px solid #25a1ba;
    margin-top: 50px;
    background-size: 593px 98px;
    width: 593px;
    height: 98px;
    line-height: 98px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
}
div.renwuBoxCls>div:nth-child(1){
    float: left;
    background-color: #365acb;
    color: #f4f17d;
    font-size: 30px;
    text-indent: 50px;
    padding-right: 40px;
}
div.renwuBoxCls>div:nth-child(2){
    float: left;
    background-color: #c2be6c;
    color: #0160fc;
    font-size: 30px;
    text-indent: 50px;
    flex: 1;
}
div.nsyjBoxCls{
    margin-top: 80px;
    margin-bottom: 80px;
    background-size: 694px 61px;
    width: 694px;
    height: 61px;
    line-height: 61px;
    background-image: url(../../../assets/images/dataScreen/rwbg2.png);

}

div.nsyjBoxCls>div:nth-child(1){
    background-image: url(../../../assets/images/dataScreen/baojin.png);
    background-size: 30px 30px;
    background-position: 30px center;
    background-repeat: no-repeat;
    text-indent: 100px;
    font-size: 32px;
    color: #fff;
    float: left;
    
}

div.nsyjBoxCls>div:nth-child(2){
    font-size: 25px;
    color: #fff;
    float: right;
    margin-right: 40px;
    
}
.speed {
    width: 100%;
    height: 80px;
    border: 1px solid #0D4867;
    font-size: 33px;
}
.speed-name {
    color:#03DB22;
    
}
.speed-item {
    width: 50%;
    box-sizing: border-box;
    padding-left: 30px;
}
.speed-line {
    width: 3px;
    height: 40px;
    background: #0888B7;
}
.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.videdo-box {
    display: flex;
    align-items: center;
}
.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}
.video-title {
    font-size: 65px;
    color:#fff;
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}
.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;    
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;    
}
.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.map-box-title {
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    padding-left: 54px;
    color: #99B3DB;
    border-bottom: 1px solid #0F2E5F;
}
.map-box-titleImg {
    width: 35px;
    height: 35px;
    background: url(../../../assets/images/dataScreen/title-logo.png) no-repeat;
    background-size: 100% 100%;    
    margin-right: 39px;
}
.map-box-titleText {
    margin-right: 97px;
    font-size: 30px;
    cursor: pointer;
    position: relative;
    z-index: 10;
}
.chooseTitle {
    color: #fff !important;
}
.map-box-titleText >span {
    display: inline-block;
    width: 100%;
    height: 10px;
    background: rgba(26, 110, 247, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}
div.vedioCls {
    width: 812px;
    height: 640px;
    background: #0E1639;
    border: 5px solid #123171;
    float: left;
    margin-top: 28px;
    margin-left: 28px;
    /* margin-bottom: 20px; */
    position: relative;
}

div.vedioCls>div.vedioTitleCls {
    background-image: url("../../../assets/images/dataScreen/vedioTitlebg.png");
    background-size: 503px 64px;
    position: absolute;
    font-size: 30px;
    color: #fff;
    bottom: 20px;
    right: 0px;
    width: 503px;
    height: 64px;
    line-height: 64px;
    text-align: right;
    padding-right: 50px;
}
.left_content{
        position: relative;
        top: 52px;
    }
.map-box {
        position: relative;
        float: left;
        width: 1720px;
        height: 1508px;
        margin-right: 89px;
        border: 5px solid #113571;
    }
.map-left {
        margin-top: 133px;
        margin-left: 54px;
        position: absolute;
        z-index: 99;
    }

.map-left-item {
    width: 336px;
    height: 155px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    background: url(../../../assets/images/dataScreen/ds1_bg_box.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    /* padding-left: 130px; */
    /*position: relative;*/
}
    .map-left-icon {
        width: 125px;
        height: 95px;
        background: url(../../../assets/images/dataScreen/ds_icon2.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-2 {
        background: url(../../../assets/images/dataScreen/ds_icon9.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-3 {
        background: url(../../../assets/images/dataScreen/ds_icon7.png) no-repeat;
        background-size: 100% 100%;
    }
.map-img {
        width: 1720px;
        height: 1400px;
        background: url(../../../assets/images/dataScreen/malinshu-map.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        /* z-index: 100; */
    }
    .box_j {
        width: 32px;
        height: 32px;
        position: absolute;
    }
    .lt_ {
        background: url(../../../assets/images/dataScreen/sheep/lt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        left: -5px;
    }
    .rt_ {
        background: url(../../../assets/images/dataScreen/sheep/rt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        right: -5px;
    }
    .lb_ {
        background: url(../../../assets/images/dataScreen/sheep/lb.png) no-repeat;
        background-size: 100% 100%;
        left: -5px;
        bottom: -5px;
    }
    .rb_ {
        background: url(../../../assets/images/dataScreen/sheep/rb.png) no-repeat;
        background-size: 100% 100%;
        right: -5px;
        bottom: -5px;
    }
</style>