
<template>
  <div class="echarts-box">
    <div id="container" v-if="datalist"></div>

    
  </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  data(){
    return {
        datalist: []
    }
  },    
  name: "HelloWorld",
  props: {
    msg: String,
  },
  mounted() {
    // this.moreChart();
  },
  methods: {
    moreChart() {
        var xData = [];
        var yStandard = [];
        for(let i = 0; i < this.datalist.length; i++) {
            xData.push(this.datalist[i].eggLayingDate);
            yStandard.push(this.datalist[i].eggLayingCount);
        }
      var chartDom = document.getElementById('container');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        fontSize: 50
                    },
                    borderColor: 'rgba(0, 0, 0, 0)',
                    padding:[ 60, 30 ,190, 230 ],
                    extraCssText: `background:url(${require("../../../../src/assets/images/dataScreen/egg/eee.png")}) no-repeat center center ;min-width:457px;height:90px;background-size:100% 100%;`,
                    formatter: function(params) {
                        var result = ''
                        params.forEach(function (item) {
                            console.log(item)
                            result += `
                                <div>
                                    <div>
                                        <div style="color: #ccc; font-size: 40px;">${item.axisValue}</div>
                                        <div style="color: #FFDC33; font-size: 60px;">${item.data}</div>
                                    </div>
                                </div>
                            `  + "</br>" 
                            // + dotHtml+ ' ' + item.seriesName + ' ' + item.data
                        })
                        return result
                    }
                },
                grid: {
                    left: 150,
                    top: 30,
                    bottom: 130,
                    right: 60
                },
                // legend: {
                //     icon: 'rectangle',
                //     data: ['标准', '测定'],
                //     right: '4%'
                // },
                toolbox: {
                    show: false,
                    textStyle: {
                        color: '#fff',
                        fontSize: 50
                    },
                    
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        dataView: {readOnly: false},
                        magicType: {type: ['line', 'bar']},
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xData,
                    nameTextStyle :{
					    fontSize: 50
				    },
 
                    axisTick: {
                    show: false,  //刻度线
                    },

                    axisLabel: {
                        interval: 0,
                        rotate: 35,
                        margin: 40,
                        textStyle: {
                            color: '#CCCCCC',
                            fontSize: 50,
                            
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            type: 'solid',
                            color: '#ccc',
                            width: 6
                        }
                    },

                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false,
                        lineStyle:{
                            color: '#CCCCCC',
                            width: 10,
                            type: 'solid'
                        }
                     },
                    axisLabel: {
                        textStyle: {
                            color: '#CCCCCC',
                            fontSize: 50
                        },
                    },
                    axisLine: {
                        lineStyle: {
                        type: 'solid',
                        color: '#fff',
                            width: 6
                        }
                    },
                },
                series: [
                    {
                        name: '产蛋(颗)',
                        type: 'line',
                        data: yStandard,
                        //smooth: 2,
                        symbolSize: 25,
                        symbol:'circle',
                        color: '#3de2d6',

                        showSymbol: false,
                        lineStyle:{
                            width: 8,
                            color: '#D5CC61'
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#575A36'
                            }, {
                                offset: 1,
                                color: '#0B1834'
                            }])
                        },
                    },
                ]
        };
        option && myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss"  scoped>

    .charts-box, #container {
        width: 100%;
        height: 1200px;
    }
</style>