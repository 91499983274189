<template>
    <div style="display: flex; justify-content: center">
        <div class="map-box mt20" style="flex-shrink: 0; margin-right: 0">
            <div class="box_j lt_"></div>
                     
                
            <div class="box_j rb_"></div>
            <div class="map-box-title">
                <div class="map-box-titleImg"></div>
                <div class="map-box-titleText" @click="chooseTab(1)" :class="isChoose == 1 ? 'chooseTitle' : ''">马铃薯产业分布<span v-if="isChoose == 1"></span></div>
                <div class="map-box-titleText" @click="chooseTab(2)" :class="isChoose == 2 ? 'chooseTitle' : ''">大地马铃薯示范基地<span v-if="isChoose == 2"></span></div>
            </div>
            <div class="left_content" style="top: 20px" v-if="isTrue && isChoose == 2">
                        <div class="vedioCls" v-for="(item, i) in ddObj.datascreen5CameraBOSType1" :key="i">
                            <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="environmentalAttribute" :flv="item.deviceUrl" />
                            <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="environmentalAttribute" :flv="item.deviceUrl"/>
                            <div class="vedioTitleCls"  @click="transitionFun(item.deviceUrl, item.installArea)">{{item.installArea}}</div>
                        </div>
                    
                    </div>
            <div v-if="isChoose == 1">
                <div class="map-left">
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-1"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs50">{{ ddObj.serviceArea || '' }}</div>
                        <div class="fs20 cfff mt10">{{ddObj.serviceAreaDesc|| ''}}</div>
                        <div class="fs20 cfff mt10">{{ddObj.serviceAreaUnit|| ''}}</div>
                    </div>
                </div>
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-2"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs50">{{ ddObj.serviceEnterprise || '' }}</div>
                        <div class="fs20 cfff mt10">{{ ddObj.serviceEnterpriseDesc || '' }}</div>
                        <div class="fs20 cfff mt10">{{ ddObj.serviceEnterpriseUnit || '' }}</div>
                    </div>
                </div>
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-3"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs50">{{ddObj.iotEquipmentCount || ''}}</div>
                        <div class="fs20 cfff mt10">{{ddObj.iotEquipmentCountDesc || ''}}</div>
                        <div class="fs20 cfff mt10">{{ddObj.iotEquipmentCountUnit || ''}}</div>
                    </div>
                </div>
            </div>
            <div class="map-img" v-if="isShow">
                <baidu-map :center="center" :zoom="zoom" @ready="handler" style="height: 99%; width: 99%" :scroll-wheel-zoom='true'>
                </baidu-map>


            </div>
            </div>
            
        </div>


        <div class="right animate__animated animate__fadeInRight">
            <div class="header-text">{{city.enterpriseName}}</div>
            <div class="header"></div>
            <div style="display:flex;">
                <div class="base-box">
                    <div class="base-title-box" >
                        <div class="base-title">基地大数据</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div class="base-content" style="padding-left: 25px;">
                        <div class="base-content-item">
                            <div style="margin-right: 70px;">
                                <div class="item-title">{{ddObj.jidiBigdataTotalPlantingArea || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataTotalPlantingAreaDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataTotalPlantingAreaUnit || ''}}</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item" style="margin-left: 70px;margin-right: 60px;">
                            <div style="margin-right:60px;">
                                <div class="item-title">{{ddObj.jidiBigdataMicroPotato || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataMicroPotatoDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataMicroPotatoUnit || ''}}</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item">
                            <div>
                                <div class="item-title">{{ddObj.jidiBigdataDetoxificationGroup || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataDetoxificationGroupDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataDetoxificationGroupUnit || ''}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="base-content-item" style="margin-top:50px;">
                            <div style="margin-right:60px;">
                                <div class="item-title">{{ddObj.jidiBigdataFullSunlight || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataFullSunlightDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataFullSunlightUnit || ''}}</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item" style="margin: 50px 60px 0 70px;">
                            <div style="margin-right:60px;">
                                <div class="item-title">{{ddObj.jidiBigdataStorageOfPotato || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataStorageOfPotatoDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataStorageOfPotatoUnit || ''}}</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item" style="margin-top:50px;">
                            <div>
                                <div class="item-title">{{ddObj.jidiBigdataOriginalSeedPotato || ''}}</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataOriginalSeedPotatoDesc || 0}}</span>
                                    <span class="item-text-company">{{ddObj.jidiBigdataOriginalSeedPotatoUnit || ''}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="base-title-box" style="margin-top:80px;margin-bottom:40px;">
                        <div>
                            <div style="margin-right:200px;display:flex;align-items: center">
                                <div class="base-title">基地人员</div>
                                <div class="base-title-img"></div>
                            </div>
                        </div>

                        <div>
                            <div style="display:flex; align-items: center">
                                <div class="base-title">基地农机</div>
                                <div class="base-title-img"></div>
                            </div>
                        </div>
                    </div>
                    <div class="base-information-box flex">
                        <div class="charts1">
                            <Highecharts1 ref="jdry"></Highecharts1>
                            <div class="echarts-num">{{renCount}}人</div>
                        </div>
                        <div class="rank-box fs29 ml20">
                            <div v-for="(item,index) in ddObj.datascreen5MachineryBOList" :key="index">
                                <div class="mb10 ta_l">
                                    <span class="">{{item.machineryName}}</span>
                                    <span class="ml12">{{item.machineryCount || 0}}台</span>
                                </div>
                                <div class="supplies-line-box supplies-line-box-blue flex f_ai_c mb19" :style="{width: (item.machineryCount / maxCount) * 400 + 'px'}">
                                    <div class="supplies-line supplies-line-blue"></div>
                                    <div class="supplies-inner-circle supplies-inner-circle-blue"></div>
                                    <div class="supplies-outer-circle supplies-outer-blue"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div class="base-title-box" style="margin-top:60px;margin-bottom:40px;">
                    <div class="base-title">基地活动资讯</div>
                    <div class="base-title-img"></div>
                  </div>
                  <div class="base-information-boxd">
                    <div class="base-img">
                      <img :src="ddObj.consult1Url">
                      <div>{{ddObj.consult1}}<span>{{ddObj.consult1Time}}</span></div>
                    </div>
                    <div class="base-img">
                      <img :src="ddObj.consult2Url">
                      <div>{{ddObj.consult2}}<span>{{ddObj.consult2Time}}</span></div>
                    </div>
                  </div>
                </div>
                <div class="farm">
                    <div class="base-title-box">
                        <div class="base-title">地块信息</div>
                        <div class="base-title-img"></div>
                    </div>
                    <!-- <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="12"> -->
                    <div id="dkyyp" style="height: 828px; overflow: hidden">
                        <div class="farm-item-box" id="dkyyp1">
                            <div class="farm-item" v-for="(item,index) in datascreen5MassifBOList" :key="index">
                                <div class="farm-item-title">{{item.farmName}}</div>
                                <div class="farm-item-subheading">{{item.landNum}}</div>
                                <div class="farm-item-list-box">
                                    <div class="list-left">农作物</div>
                                    <div class="list-right">{{item.cropsName}}</div>
                                </div>
                                <div class="farm-item-list-box" style="margin:22px 0;">
                                    <div class="list-left">地块面积</div>
                                    <div class="list-right">{{item.areaNum || 0}}亩</div>
                                </div>
                                <div class="farm-item-list-box">
                                    <div class="list-left">管理员</div>
                                    <div class="list-right">{{item.adminName}}</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="farm-item-box" id="dkyyp2" v-if="isYes1">
                            <div class="farm-item" v-for="(item,index) in datascreen5MassifBOList" :key="index">
                                <div class="farm-item-title">{{item.farmName}}</div>
                                <div class="farm-item-subheading">{{item.landNum}}</div>
                                <div class="farm-item-list-box">
                                    <div class="list-left">农作物</div>
                                    <div class="list-right">{{item.cropsName}}</div>
                                </div>
                                <div class="farm-item-list-box" style="margin:32px 0;">
                                    <div class="list-left">地块面积</div>
                                    <div class="list-right">{{item.areaNum || 0}}亩</div>
                                </div>
                                <div class="farm-item-list-box">
                                    <div class="list-left">管理员</div>
                                    <div class="list-right">{{item.adminName}}</div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                        
                    <!-- </marquee> -->
                    <div class="base-title-box" style="margin-top: 60px;">
                        <div class="base-title">基地实时画面</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div class="base-information-boxs" v-if="isTrue">
                        <div class="base-img" style="position:relative; height: 244px; width: 400px" :key="i" v-for="(item, i) in ddObj.datascreen5CameraBOSType2">
                            <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+6)" :attribute="webcamAttribute2" :flv="item.deviceUrl" />
                            <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+6)"  :attribute="webcamAttribute2" :flv="item.deviceUrl"/>
                            <!-- <EZUIKitJs2 :msg="'video5'" :title="video5Title" :attribute="webcamAttribute2"
                                :flv="ddObj.jidiSmallVedio1Url" /> -->
                            <div class="base-text" @click="transitionFun(item.deviceUrl, item.installArea, item.deviceFactory)" style="bottom: 20px; font-size: 26px">{{item.installArea}}</div>
                        </div>
                        <!-- <div class="base-img" style="position:relative;">
                            <EZUIKitJs2 :msg="'video6'" :title="video6Title" :attribute="webcamAttribute2"
                                :flv="ddObj.jidiSmallVedio2Url" />

                            <div class="base-text" style="bottom:220px;">{{ddObj.jidiSmallVedio2Title}}</div>
                        </div> -->
                    </div>
                </div>
                <div class="weather-station">
                    <div class="base-title-box" style="margin-bottom:50px;">
                        <div class="base-title" style="margin-right:0;">基地气象站</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div class="weather-dashboard item-box">
                        <div class="weather-left">
                            {{qxList.iotDataBO.ambientTemperature ? parseInt(qxList.iotDataBO.ambientTemperature) : '--'}}
                        </div>
                        <div class="weather-right">
                            <div>
                                <div class="qxzKeyCls qxzIndex1Cls">风速</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.windSpeed ? qxList.iotDataBO.windSpeed : 0}}m/s</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex2Cls">湿度</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.ambientHumidity ? (Number(qxList.iotDataBO.ambientHumidity)).toFixed(0) : 0}}%Rh</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex3Cls">雨量</div>
                                <div>
                                    <div class="qxzStatusCls s2Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.rainfall ? qxList.iotDataBO.rainfall : 0}}mm</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex4Cls">CO2</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.co2 ? qxList.iotDataBO.co2 : 0}}ppm</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex5Cls">光照</div>
                                <div>
                                    <div class="qxzStatusCls s3Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.lightIntensity ? qxList.iotDataBO.lightIntensity : 0}}Lux</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex6Cls">大气压</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">{{qxList.iotDataBO.pressure ? qxList.iotDataBO.pressure : 0}}hPa</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="base-title-box" style="margin-bottom:50px;margin-top: 0px;">
                        <div class="base-title" style="margin-right:0;">基地土壤指标监测</div>
                        <div class="base-title-img"></div>
                    </div>

                    <div style="height:300px">
                        <div class="jttrzbjcColumnCls jttrzbjcHeaderCls">
                            <div>地块</div>
                            <div>土壤PH值</div>
                            <div>土壤温度</div>
                            <div>土壤水分</div>
                            <div>土壤盐分</div>
                            <div>土壤电导率</div>
                        </div>
                        <!-- <marquee class="marquee-list" direction="up" style="height: 500px" behavior="scroll" scrollamount="5"> -->
                            <div id="td5tryyp" style="height: 160px; overflow: hidden; clear: both">
                                <div id="td5tryyp1">
                                    <div class="jttrzbjcColumnCls jttrzbjcRowCls" v-for="(item,index) in trList" :key="index">
                                        <div>{{item.key}}</div>
                                        <div>{{item.iotDataBO.ph ? item.iotDataBO.ph : 7.1}}</div>
                                        <div>{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</div>
                                        <div>{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</div>
                                        <!-- <div>{{item.iotDataBO.salt != null ? item.iotDataBO.salt : '--'}}ppm</div> -->
                                        <div v-if="item.deviceFactory == 'JD'">{{item.iotDataBO.soilCond != null ? (item.iotDataBO.soilCond > 100 ? item.iotDataBO.soilCond - 100 : item.iotDataBO.soilCond) / 2 : '--'}}ppm</div>
                                        <div v-else>{{item.iotDataBO.salt != null ? item.iotDataBO.salt : '--'}}ppm</div>

                                        <div>{{item.iotDataBO.soilCond ? (item.iotDataBO.soilCond > 100 ? item.iotDataBO.soilCond - 100 : item.iotDataBO.soilCond) : '--'}}uS/cm</div>
                                    </div>
                                </div>
                                <!-- <div id="td5tryyp2" v-if="isYes2">
                                    <div class="jttrzbjcColumnCls jttrzbjcRowCls" v-for="(item,index) in trList" :key="index">
                                        <div>{{item.key}}</div>
                                        <div>{{item.iotDataBO.ph ? item.iotDataBO.ph : '--'}}</div>
                                        <div>{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</div>
                                        <div>{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</div>
                                        <div>{{item.iotDataBO.salt ? item.iotDataBO.salt : '--'}}</div>
                                        <div>{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : '--'}}uS/cm</div>
                                    </div>
                                </div> -->
                            </div>
                            
                        <!-- </marquee> -->

                        
                    </div>
                    <div class="rewuyujinCls">
                        <div class="nsyjCls">
                            <div class="base-title-box" style="">
                                <div class="base-title" style="margin-right:0;">农事预警</div>
                                <div class="base-title-img"></div>
                            </div>
                            <div class="nsyjClsBiaoti">
                                <span>地块位置</span>
                                <span>实际检测值</span>
                                <span>预警详情</span>
                                <span>预警时间</span>
                            </div>
                            <!-- <marquee class="marquee-list" direction="up" style="height: 450px" behavior="scroll" scrollamount="5"> -->
                                <div id="nsyj5yyp" style="height: 280px; overflow: hidden">
                                    <div id="nsyj5yyp1">
                                        <div class="nsyjClsBiaotiCls" v-for="(item,index) in warnList" :key="index">
                                            <span>
                                                <img width="23px" src="../../../assets/images/dataScreen/baojin.png" v-if="item.isWarn==0">
                                                <img width="23px" src="../../../assets/images/dataScreen/warning.png" v-else>
                                                <span style="width:90%;padding-left: 6px;">{{item.warnName}}</span>
                                            </span>
                                            <span>{{item.detectionValue}}</span>
                                            <span v-if="item.isWarn==0" style="color: #FFA800">
                                                <img width="23px" src="../../../assets/images/dataScreen/up.png">
                                                <span style="width:80%;padding-left:15px;">{{item.exceedanceName}}</span>
                                            </span>
                                            <span v-else style="color: #FF2600">
                                                <img width="23px" src="../../../assets/images/dataScreen/down.png">
                                                <span style="width:80%;padding-left:15px;">{{item.exceedanceName}}</span>                              
                                            </span>
                                            <span>{{dateFormat(item.createTime)}}</span>
                                        </div>
                                    </div>
                                    <!-- <div id="nsyj5yyp2" v-if="isYes3">
                                        <div class="nsyjClsBiaotiCls" v-for="(item,index) in warnList" :key="index">
                                            <span>
                                                <img width="50px" src="../../../assets/images/dataScreen/baojin.png" v-if="item.isWarn==0">
                                                <img width="50px" src="../../../assets/images/dataScreen/warning.png" v-else>
                                                <span style="width:90%;padding-left:15px;">{{item.warnName}}</span>
                                            </span>
                                            <span>{{item.detectionValue}}</span>
                                            <span v-if="item.isWarn==0" style="color: #FFA800">
                                                <img width="50px" src="../../../assets/images/dataScreen/up.png">
                                                <span style="width:80%;padding-left:15px;">{{item.exceedanceName}}</span>
                                            </span>
                                            <span v-else style="color: #FF2600">
                                                <img width="50px" src="../../../assets/images/dataScreen/down.png">
                                                <span style="width:80%;padding-left:15px;">{{item.exceedanceName}}</span>                              
                                            </span>
                                            <span>{{dateFormat(item.createTime)}}</span>
                                        </div>
                                    </div> -->
                                </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import Highecharts1 from '../highecharts/newScreen6/highecharts1.vue'
export default {
    components: {
        EZUIKitJs,
        EZUIKitJs2,
        Highecharts1
    },
    data(){
        return {
            city: {},
            isChoose: 1,
            isTrue: false,
            ddObj: {},
            isShow: false,
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12,
            townList: [],
            labels: [],
            potatoData: [],
            datascreen5MassifBOList: [],
            maxCount: 0,
            renCount: 0,
            bMapObj: null,
            mapObj: null,
            environmentalAttribute: {
                width: 531,
                height: 420
            },
            webcamAttribute2: {
                width: 400,
                height: 244
            },
            qxList: {},
            trList: [],
            warnList: []
        }
    },
    mounted(){
        this.init();
         this.iot();
        setInterval(() => {
            this.iot();
        },60000)
    },
    methods: {
        iot(){
            this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '05'}).then(res => {
                if(res.data.length > 0) {
                    this.qxList = res.data[0];
                } else {
                    this.qxList.iotDataBO = {
                        ambientTemperature: ''
                    };
                }
                
                // this.$nextTick(() => {
                //     this.autoplay1();
                // })
                // this.qxList = res.data;

   
            })
            var row = JSON.parse(sessionStorage.getItem("chooseId"));
            this.qa.queryIotDataByDate({deviceType: 'SENSOR_TR', datascreenNo: '05'}).then(res => {
                for(var i=0; i<res.data.length; i++) {
                    if(res.data[i].iotDataBO == null) {
                        var obj = {
                            key: '--',
                            ph: '--',
                            soilTemp: '--',
                            soilHumi: '--',
                            salt: '--',
                            salt: '--',
                        }
                        res.data[i].iotDataBO = obj
                    }
                }
                this.trList = res.data;
            })
            this.qa.datascreen5WarnQueryForPageLists({datascreen3EnterpriseId: row.enterpriseId}).then(res => {
                this.warnList = res.data;
                // this.$nextTick(() => {
                //     this.autoplay3();
                // })
            })
        },
        handler ({BMap, map}) {
            this.bMapObj = BMap;
            this.mapObj = map;
            this.labels = [];
            var row = JSON.parse(sessionStorage.getItem("chooseId"));
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/mark.png"), new BMap.Size(60, 108), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(10, 25),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].mapX, this.potatoData[i].mapY);   
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);   
                var content = this.potatoData[i].enterpriseName +'<br />'+this.potatoData[i].enterpriseValue;
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(20, -140)
                })  
                this.labels.push(label);
                map.addOverlay(label);
                this.clickBox(this.potatoData[i], i);                       // 将标注添加到地图中
                // 
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '29px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    height: '128px',
                    padding: '10px 20px 26px',
                    paddingBottom: '40px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            for(let i = 0; i < this.townList.length; i++) {
                var point = new BMap.Point(this.townList[i].mapX, this.townList[i].mapY);   
                var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -126px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.townList[i].townshipName + '</div><div>'+this.townList[i].yuliu1 +'</div>'+'<div>'+this.townList[i].yuliu2+'</div>'+'<div>'+this.townList[i].yuliu3+'</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-200, -268)
                })  
                map.addOverlay(label);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '29px',
                    border: '0',
                    backgroundImage: (this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")' : '',
                    backgroundColor: 'transparent',
                    height: '128px',
                    padding: '10px 20px 26px',
                    paddingBottom: '40px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            var yhPoint = new BMap.Point(this.ddObj.yhList[0].mapX,this.ddObj.yhList[0].mapY);
            var yhMaker = new BMap.Marker(yhPoint, {icon: myIcon});
            map.addOverlay(yhMaker);   
             var yhContent = this.ddObj.yhList[0].enterpriseName + '<br />'+ this.ddObj.yhList[0].enterpriseValue + '亩';
                var yhLabel = new BMap.Label(yhContent, {       // 创建文本标注
                    position: yhPoint,
                    offset: new BMap.Size(20, -140)
                })  

                map.addOverlay(yhLabel);
                var that = this;
            yhLabel.addEventListener('click', function(){
                that.$parent.tabsTypeClick('4');
            })
            yhLabel.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '29px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    height: '128px',
                    padding: '10px 20px 26px',
                    paddingBottom: '40px',
                    backgroundSize: '100% 100%'
                })
            var point = new BMap.Point(109.61446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            // var marker = new BMap.Marker(point) // 创建标注
            // map.addOverlay(marker) // 将标注添加到地图中
            // var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            // map.addOverlay(circle)
            map.enableScrollWheelZoom(true);
        },
        clickBox(row, index) {
          var that = this;
          this.labels[index].addEventListener('click', function(){
              that.isShow = false;
              sessionStorage.setItem("chooseId", JSON.stringify(row));
              let obj = {
                  BMap: that.bMapObj,
                  map: that.mapObj
              }
              that.handler(obj);
              that.isShow = true;              
              that.init();
              that.doPlay("");
              that.iot();
          })        
        },
        init(){
            this.qa.datascreen3TownshipMapConfqueryForList({}).then(res => {
                this.townList = res.data;
            })
            this.qa.querydatascreen3EnterpriseMapInfo({}).then(res => {
                this.potatoData = res.data;
            })
            this.city = JSON.parse(sessionStorage.getItem("chooseId"));
            this.qa.queryPlantLandListForPdp({orgType: this.city.orgType}).then(res => {

                var list = JSON.parse(res.data).data.list;
                var arrList = [];
                for(let i = 0; i < list.length; i++) {
                    for(let j = 0; j < list[i].plantLandRspBOList.length; j++) {
                        list[i].plantLandRspBOList[j].farmName = list[i].farmName;
                        arrList.push(list[i].plantLandRspBOList[j]);
                    }
                    
                }
                
                this.datascreen5MassifBOList = arrList;
                // this.$nextTick(() => {
                //     this.autoplay();
                // })

                
            })
            this.qa.queryDatascreen5ById({datascreen3EnterpriseId: this.city.enterpriseId,useEnterprise: this.city.enterpriseName}).then(res => {
                this.ddObj = res.data;
                this.isShow = true;
                this.isTrue = true;
                if(this.ddObj && this.ddObj.datascreen5MachineryBOList && this.ddObj.datascreen5MachineryBOList.length > 0) {
                    for(let i = 0; i < this.ddObj.datascreen5MachineryBOList.length; i++) {
                        if(Number(this.ddObj.datascreen5MachineryBOList[i].machineryCount) > Number(this.maxCount)) {
                            this.maxCount = this.ddObj.datascreen5MachineryBOList[i].machineryCount;
                        }
                    }
                }
                
                //基地人员
                let list = [];
                if(this.ddObj && this.ddObj.datascreen5JobBOList && this.ddObj.datascreen5JobBOList.length > 0) {
                    for(let i = 0; i < this.ddObj.datascreen5JobBOList.length; i++) {
                        this.renCount += parseInt(this.ddObj.datascreen5JobBOList[i].jobPersonCount);
                        let obj = {};
                        obj.value = this.ddObj.datascreen5JobBOList[i].jobPersonCount;
                        obj.name = this.ddObj.datascreen5JobBOList[i].jobName + ' ' + this.ddObj.datascreen5JobBOList[i].jobPersonCount + '人';
                        obj.itemStyle = {
                            color: `rgb(${parseInt(Math.random()*255)},${parseInt(Math.random()*255)},${parseInt(Math.random()*255)})`
                        }
                        list.push(obj)
                    }
                }
                

                this.$nextTick(function () {
                    this.$refs.jdry.Rydata = list;
                    this.$refs.jdry.actualCount();
                })
            })
        },
        chooseTab(type){
            this.isChoose = type;
        },
        dateFormat(dateStr){
            var date = new Date(dateStr);
            console.log(date)
            var year = date.getFullYear();
            var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1): date.getMonth() + 1;
            var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return year + '-' + month + '-' + day;
        },
    }
}
</script>

<style scoped>
.right {
    /*width: 8173px;*/
    margin-left: 83px;
}

.header {
    width: 3100px;
    height: 72px;
    background: url(../../../assets/images/dataScreen/s_title.png) no-repeat;
    background-size: 100% 100%;

    text-align: center;

    margin: -50px auto 0;
}

.header-text {
    width: 3100px;
    height: 72px;
    color: #fff;
    font-size: 44px;
    margin: 20px auto 0;
    text-align: center;
}

.content_left {
    width: 3157px;
    height: 2779px;
    background: rgba(14, 22, 57, 0.78);
    border: 5px solid rgba(18, 49, 113, 0.78);
    /*opacity: 0.78;*/
    margin-top: 86px;
    padding-left: 39px;
}

.content-top-left-corner {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/ysSt-jiao.png) no-repeat;
}

.content-bottom-right-corner {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/zxSt-jiao.png) no-repeat;
}

.content-top-title {
    width: 1043px;
    height: 147px;
    line-height: 147px;
    padding-left: 60px;
    margin-top: 45px;
    background: url(../../../assets/images/dataScreen/title-left.png) no-repeat;
    font-size: 75px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
}

.content-item {
    width: 1510px;
    height: 1191px;
    background: rgba(3, 115, 201, 0.18);
    border: 5px solid rgba(71, 214, 255, 0.18);
    /*opacity: 0.18;*/
    margin-top: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-item-img {
    width: 1439px;
    height: 1111px;
    /* background: red; */
}

.content-item-title {
    width: 1440px;
    height: 164px;
    line-height: 164px;
    text-align: right;
    padding-right: 56px;
    position: absolute;
    bottom: 35px;
    right: 29px;
    background: url(../../../assets/images/dataScreen/title-right.png) no-repeat;
    background-size: 100%;
    font-size: 64px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
}

.base-box {
    width: 1000px;
    height: 1364px;
    /* background: rgba(14, 22, 57, 0.78); */
    border: 5px solid rgba(71, 214, 255, 0.18);
    margin-top: 50px;
    /* margin-left: 83px; */
    padding: 36px;
}

.base-title-box {
    display: flex;
    align-items: center;

}

.base-title {
    /*width: 392px;*/
    /*height: 61px;*/
    font-size: 41px;
    font-family: FZZDHJW;
    font-weight: 600;
    color: #FFFFFF;
    margin-right: 40px;
}

.base-title-img {
    width: 100px;
    height: 39px;
    background: url(../../../assets/images/dataScreen/tit-bg.png) no-repeat 0 6px;
    background-size: 100px 29px;
    /* background-position: 30px 25px; */
}

.base-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.base-content-item {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-title {
    /*width: 392px;*/
    font-size: 29px;
    font-family: FZZDHJW;
    white-space: nowrap;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
}

.item-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.item-text-num {
    display: inline-block;
    font-size: 44px;
    font-weight: 600;
    color: RGBA(35, 209, 255, 1);
    margin-right: 10px;
}

.item-text-company {
    display: inline-block;
    font-size: 29px;
    color: #fff;
    margin-bottom: -6px;
}

.item-img {
    width: 7px;
    height: 62px;
    background: url(../../../assets/images/dataScreen/border.png) no-repeat;
}

.planting-area-content {
    width: 810px;
    height: 100px;
    background: RGBA(32, 63, 118, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 45px;
    padding-right: 45px;
    color: #FFFFFF;
    margin-top: 45px;
}

.planting-area-contents {
    height: 100px;
    background: RGBA(32, 63, 118, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 45px;
    padding-right: 45px;
    color: #FFFFFF;
    margin-top: 45px;
}

.planting-area-item {
    min-width: 154px;
    font-size: 46px;
}

.item-line {
    width: 5px;
    height: 76px;
    background: #FFFFFF;
}

.base-information-box {
    width: 970px;
    height: 340px;
    /* background: url(../../../assets/images/dataScreen/renyuan.png) no-repeat;*/
    background-size: 100% 100%;
}

.charts1 {
    width: 50%;
    height: 100%;
    position: relative;
}

.echarts-num {
    position: absolute;
    top: 38%;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 29px;
    color: #FDBA00;
    text-align: center;
    height: 50px;
}

.base-information-boxs {
    width: 100%;
    height: 244px;
    display: flex;
    justify-content: space-between;
    padding-right: 61px;
    margin-top: 40px;
    position: relative;
}

/*.base-img {*/
/*    width: 816px;*/
/*    height: 514px;*/
/*    border: 5px solid rgba(8, 110, 148, 1);*/
/*    background: red;*/
/*    position: relative;*/
/*}*/

.base-text {
    font-size: 53px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    position: absolute;
    bottom: 38px;
    right: 47px;
}

.farm {
    width: 1000px;
    height: 1364px;
    /* background: rgba(14, 22, 57, 0.78); */
    border: 5px solid #123171;
    margin-left: 40px;
    margin-top: 50px;
    padding: 36px;
}

.farm-item-box {
    padding-left: 31px;
    display: flex;
    flex-wrap: wrap;
}

.farm-item {
    width: 390px;
    height: 245px;
    background: url(../../../assets/images/dataScreen/nongchang.png) no-repeat;
    background-size: 100% 100%;
    /* padding: 10px 90px 0 81px; */
    padding: 0 40px;
    margin-right: 36px;
    margin-top: 52px;
}

.farm-item-title {
    height: 62px;
    font-size: 32px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #EEC915;
    line-height: 43px;
    text-align: center;
    margin-top: -30px;
}

.farm-item-subheading {
    height: 33px;
    font-size: 33px;
    font-family: Swiss721BT;
    font-weight: 800;
    color: #EEC915;
    line-height: 33px;
    text-align: center;
    margin-top: 24px;
}

.farm-item-list-box {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    font-family: NotoSansHans;
    font-weight: 400;
    color: #FFFFFF;
}

.list-right {
    font-weight: 500;
    color: #0291C9;
}

.weather-station {
    width: 1000px;
    height: 1364px;
    /*background: rgba(14, 22, 57, 1);*/
    border: 5px solid rgba(18, 49, 113, 1);
    margin-top: 50px;
    margin-left: 40px;
    padding: 36px;
}

.weather-dashboard {
    height: 365px;
    display: flex;
    padding: 0 30px;
}

.rank-box {
    width: 50%;
    height: 100%;
    color: #ccc;
}

.supplies-item {
    width: 230px;
    text-align: left;
}

.supplies-line-box {
    width: 203px;
    height: 17px;
    position: relative;
}

.supplies-line {
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, rgba(45, 233, 180, 0.1), #2DE9B4);
}

.supplies-line-blue {
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, rgba(0, 90, 238, 0.1), #005AEE);
}

.supplies-inner-circle {
    width: 8px;
    height: 8px;
    background: #77E9D8;
    border-radius: 50%;
    position: absolute;
    right: 0;
}

.supplies-inner-circle-blue {
    background: #28BEFD;
}

.supplies-outer-circle {
    width: 25px;
    height: 25px;
    border: 3px solid rgba(45, 233, 180, 0.3);
    box-shadow: 0px 1px 5px 0px rgba(45, 233, 180, 0.8);
    border-radius: 50%;
    position: absolute;
    right: -8px;
}

.supplies-outer-circle-blue {
    box-shadow: 0px 2px 10px 0px rgba(0, 90, 238, 0.8);
}

.weather-left {
    width: 362px;
    height: 364px;
    line-height: 290px;
    background: url(../../../assets/images/dataScreen/ybp.png) no-repeat;
    background-size: 100%;
    font-size: 106px;
    text-align: center;
    color: #FFFFFF;
    float: left;
}

.weather-right {
    float: left;
    width: 500px;
    height: 600px;
    margin-left: 100px;
    margin-top: 10px;

}

.weather-right>div {
    height: 55px;
    font-size: 25px;
}

div.qxzKeyCls {
    float: left;
    color: #00de1e;
}

div.qxzKeyCls.qxzIndex1Cls {
    background: url(../../../assets/images/dataScreen/wlsb1.png) no-repeat;
    background-size: 37px 35px;
    background-position: left 5px;
    text-indent: 40px;
    
}

div.qxzKeyCls.qxzIndex2Cls {
    background: url(../../../assets/images/dataScreen/wlsb2.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
    margin-left: 37px;
}

div.qxzKeyCls.qxzIndex3Cls {
    background: url(../../../assets/images/dataScreen/wlsb3.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
    margin-left: 74px;
}

div.qxzKeyCls.qxzIndex4Cls {
    background: url(../../../assets/images/dataScreen/wlsb4.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
    margin-left: 72px;
}

div.qxzKeyCls.qxzIndex5Cls {
    background: url(../../../assets/images/dataScreen/wlsb5.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
    margin-left: 37px;
}

div.qxzKeyCls.qxzIndex6Cls {
    background: url(../../../assets/images/dataScreen/wlsb6.png) no-repeat;
    background-size: 30px 30px;
    background-position: left 5px;
    text-indent: 40px;
}

.weather-right>div>div:nth-child(2) {
    float: right;
    color: #fff;

}

div.qxzStatusCls {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    float: left;
    margin-top: 10px;
    margin-right: 10px;
}

div.qxzValCls {
    float: left;

}

div.qxzStatusCls.s1Cls {
    background-color: #00de1e;

}

div.qxzStatusCls.s2Cls {
    background-color: red;

}

div.qxzStatusCls.s3Cls {
    background-color: #ffcb00;

}

div.jttrzbjcRowCls>div {
    font-size: 23px;
    float: left;
    text-align: center;
    height: 60px;
}

div.jttrzbjcHeaderCls>div {
    font-size: 18px;
    color: #fff;
    float: left;
    background-size: 106px 80px;
    background-repeat: no-repeat;
    background-position: top center;
    height: 140px;
    padding-top: 90px;
    text-align: center;
}

div.jttrzbjcHeaderCls>div:nth-child(1) {
    background-image: url(../../../assets/images/dataScreen/turanzb1.png);

}

div.jttrzbjcHeaderCls>div:nth-child(2) {
    background-image: url(../../../assets/images/dataScreen/turanzb2.png);

}

div.jttrzbjcHeaderCls>div:nth-child(3) {
    background-image: url(../../../assets/images/dataScreen/turanzb3.png);

}

div.jttrzbjcHeaderCls>div:nth-child(4) {
    background-image: url(../../../assets/images/dataScreen/turanzb4.png);

}

div.jttrzbjcHeaderCls>div:nth-child(5) {
    background-image: url(../../../assets/images/dataScreen/turanzb5.png);

}

div.jttrzbjcHeaderCls>div:nth-child(6) {
    background-image: url(../../../assets/images/dataScreen/turanzb6.png);

}

div.jttrzbjcColumnCls>div:nth-child(1) {
    width: 180px;
}

div.jttrzbjcColumnCls>div:nth-child(2) {
    width: 140px;
}

div.jttrzbjcColumnCls>div:nth-child(3) {
    width: 150px;
}

div.jttrzbjcColumnCls>div:nth-child(4) {
    width: 150px;
}

div.jttrzbjcColumnCls>div:nth-child(5) {
    width: 150px;
}

div.jttrzbjcColumnCls>div:nth-child(6) {
    width: 150px;
}

div.jttrzbjcRowCls>div:nth-child(1) {
    color: #9fd80d;
}

div.jttrzbjcRowCls>div:nth-child(2) {
    color: #e5d500;
}

div.jttrzbjcRowCls>div:nth-child(3) {
    color: #00e73c;
}

div.jttrzbjcRowCls>div:nth-child(4) {
    color: #e5d500;
}

div.jttrzbjcRowCls>div:nth-child(5) {
    color: #00ecef;
}

div.jttrzbjcRowCls>div:nth-child(6) {
    color: #c155ed;
}

div.rewuyujinCls {
    width: 100%;
    height: 710px;
}
div.rewuyujinCls>div.rwssjdCls{
    height: 100%;
    width: 50%;
    float: left;
}
div.rewuyujinCls>div.nsyjCls{
    height: 100%;
    width: 100%;
    float: left;

}
.nsyjClsDesc{
  font-size: 48px;
  color: #FFFFFF;
  margin-top: 100px;
}
.nsyjClsDesc div{
  margin-top: 50px;
}
div.rwmcCls{
     margin-top: 50px;
    background-size: 593px 98px;
    width: 593px;
    height: 98px;
    line-height: 98px;
    background-image: url(../../../assets/images/dataScreen/rwbg1.png);
}
div.rwmcCls>div:nth-child(1){
    float: left;
    color: #fff;
    font-size: 40px;
    margin-left: 70px;
    background-image: url(../../../assets/images/dataScreen/fwd1.png);
    background-size: 50px 50px;
    background-position: center left;
    background-repeat: no-repeat;
    text-indent: 70px;
}
div.rwmcCls>div:nth-child(2){
    color: #eddd03;
    float: right;
    font-size: 50px;
    margin-right: 100px;
}
div.renwuBoxCls{
    border: 5px solid #25a1ba;
    margin-top: 50px;
    background-size: 593px 98px;
    width: 593px;
    height: 98px;
    line-height: 98px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
}
div.renwuBoxCls>div:nth-child(1){
    float: left;
    background-color: #365acb;
    color: #f4f17d;
    font-size: 30px;
    text-indent: 50px;
    padding-right: 40px;
}
div.renwuBoxCls>div:nth-child(2){
    float: left;
    background-color: #c2be6c;
    color: #0160fc;
    font-size: 30px;
    text-indent: 50px;
    flex: 1;
}
div.nsyjBoxCls{
    margin-top: 80px;
    margin-bottom: 80px;
    background-size: 694px 61px;
    width: 694px;
    height: 61px;
    line-height: 61px;
    background-image: url(../../../assets/images/dataScreen/rwbg2.png);

}

div.nsyjBoxCls>div:nth-child(1){
    background-image: url(../../../assets/images/dataScreen/baojin.png);
    background-size: 30px 30px;
    background-position: 30px center;
    background-repeat: no-repeat;
    text-indent: 100px;
    font-size: 32px;
    color: #fff;
    float: left;
    
}

div.nsyjBoxCls>div:nth-child(2){
    font-size: 25px;
    color: #fff;
    float: right;
    margin-right: 40px;
    
}

.base-information-boxd {
  display: flex;
  justify-content: space-between;
}
.base-information-boxd .base-img {
  width: 440px;
  height: 247px;
  background-size: cover;
  position: relative;
}
.base-information-boxd .base-img div{
  font-size: 20px;
  color: #FFFFFF;
  position: absolute;
  bottom: 14px;
  text-indent: 45px;
  width: 100%;
  font-weight: 500;
}
.base-information-boxd .base-img img{
  width: 440px;
  height: 247px;
}
.base-information-boxd .base-img div span{
  float: right;
  margin-right: 25px;
}
.nsyjClsBiaoti{
  width: 100%;
  height: 56px;
  line-height: 56px;
  color: #ffffff;
  font-size: 23px;
  margin-top: 36px;
}
.nsyjClsBiaoti span{
  display: inline-block;
  width: 25%;
  text-align: center;
  background: #112849;
  border-right: 1px solid #123669;
}
.nsyjClsBiaotiCls{
    display: flex;
    padding-left: 15px;
}
.nsyjClsBiaotiCls span{
    display: flex;
    height: 100px;
    width: 25%;
    text-align: center;
    font-size: 23px;
    color: #ffffff;
    /* line-height: 150px; */
    /* margin-top: 15px; */
    align-items: center;
    justify-content: center;
}
.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.videdo-box {
    display: flex;
    align-items: center;
}
.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}
.video-title {
    font-size: 65px;
    color:#fff;
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}
.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;    
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;    
}
.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.map-box-title {
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    padding-left: 54px;
    color: #99B3DB;
    border-bottom: 1px solid #0F2E5F;
}
.map-box-titleImg {
    width: 35px;
    height: 35px;
    background: url(../../../assets/images/dataScreen/title-logo.png) no-repeat;
    background-size: 100% 100%;    
    margin-right: 39px;
}
.map-box-titleText {
    margin-right: 97px;
    font-size: 30px;
    cursor: pointer;
    position: relative;
    z-index: 10;
}
.chooseTitle {
    color: #fff !important;
}
.map-box-titleText >span {
    display: inline-block;
    width: 100%;
    height: 10px;
    background: rgba(26, 110, 247, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}
div.vedioCls {
    width: 532px;
    height: 420px;
    background: #0E1639;
    border: 5px solid #123171;
    float: left;
    margin-top: 28px;
    margin-left: 28px;
    /* margin-bottom: 20px; */
    position: relative;
}

div.vedioCls>div.vedioTitleCls {
    background-image: url("../../../assets/images/dataScreen/vedioTitlebg.png");
    background-size: 503px 64px;
    position: absolute;
    font-size: 30px;
    color: #fff;
    bottom: 20px;
    right: 0px;
    width: 503px;
    height: 64px;
    line-height: 64px;
    text-align: right;
    padding-right: 50px;
}
.left_content{
        position: relative;
        top: 52px;
    }


.map-box {
        position: relative;
        float: left;
        width: 1720px;
        height: 1508px;
        margin-right: 89px;
        border: 5px solid #113571;
    }
.map-left {
        margin-top: 133px;
        margin-left: 54px;
        position: absolute;
        z-index: 99;
    }

.map-left-item {
    width: 336px;
    height: 155px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    background: url(../../../assets/images/dataScreen/ds1_bg_box.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    /* padding-left: 130px; */
    /*position: relative;*/
}
    .map-left-icon {
        width: 125px;
        height: 95px;
        background: url(../../../assets/images/dataScreen/ds_icon2.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-2 {
        background: url(../../../assets/images/dataScreen/ds_icon9.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-3 {
        background: url(../../../assets/images/dataScreen/ds_icon7.png) no-repeat;
        background-size: 100% 100%;
    }
.map-img {
        width: 1720px;
        height: 1400px;
        background: url(../../../assets/images/dataScreen/malinshu-map.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        /* z-index: 100; */
    }
    .box_j {
        width: 32px;
        height: 32px;
        position: absolute;
    }
    .lt_ {
        background: url(../../../assets/images/dataScreen/sheep/lt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        left: -5px;
    }
    .rt_ {
        background: url(../../../assets/images/dataScreen/sheep/rt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        right: -5px;
    }
    .lb_ {
        background: url(../../../assets/images/dataScreen/sheep/lb.png) no-repeat;
        background-size: 100% 100%;
        left: -5px;
        bottom: -5px;
    }
    .rb_ {
        background: url(../../../assets/images/dataScreen/sheep/rb.png) no-repeat;
        background-size: 100% 100%;
        right: -5px;
        bottom: -5px;
    }
</style>