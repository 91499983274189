<template>
    <div class="dataScreenContent">
        <div class="map-box">
            <div class="map-box-title">
                <div class="map-box-titleImg"></div>
                <div class="map-box-titleText" @click="chooseTab(1)" :class="isChoose == 1 ? 'chooseTitle' : ''">谷物杂粮产业分布<span v-if="isChoose == 1"></span></div>
                <div class="map-box-titleText" @click="chooseTab(2)" :class="isChoose == 2 ? 'chooseTitle' : ''">谷物杂粮示范基地<span v-if="isChoose == 2"></span></div>
            </div>
            <div style="display:flex;padding-left:79px;" v-if="isChoose == 1">
                <div class="tjCls">
                    <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                        <div class="dsIconCls"></div>
                        <div class="numCls">{{info.datascreen18BO.totalSownArea || 0}}<span class="dwCls"></span></div>
                        <div class="titleCls">播种总面积（亩）</div>
                    </div>
                    <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                        <div class="dsIcon2Cls"></div>
                        <div class="numCls">{{info.datascreen18BO.grainArea || 0}}<span class="dwCls"></span></div>
                        <div class="titleCls">谷物面积（亩）</div>
                    </div>
                    <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                        <div class="dsIcon3Cls"></div>
                        <div class="numCls">{{info.datascreen18BO.beansArea || 0}}<span class="dwCls"></span></div>
                        <div class="titleCls">谷物总产量（吨）</div>
                    </div>
                    <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                        <div class="dsIcon4Cls"></div>
                        <div class="numCls">{{info.datascreen18BO.totalSowingOutput || 0}}<span class="dwCls"></span></div>
                        <div class="titleCls">豆类面积（亩）</div>
                    </div>
                    <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                        <div class="dsIcon5Cls"></div>
                        <div class="numCls">{{info.datascreen18BO.demonstrationBaseArea || 0}}<span class="dwCls"></span></div>
                        <div class="titleCls">豆类总产量（吨）</div>
                    </div>
                </div>
                <div class="mapCls">
                    <baidu-map :center="center" v-if="isShow" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
                    </baidu-map>
                </div>
            </div>
            <div class="jdssJ1Cls"></div>
            <div class="jdssJ2Cls"></div>
        </div>
        <div class="main3cls">
            <div class="jdsshmCls">
                <div class="jdssJ1Cls"></div>
                <div class="jdssJ2Cls"></div>
                <div style="height:10px"></div>
                <div class="boxTitleCls">基地大数据<img src="../../../assets/images/dataScreen/boxTitleBgs.png"></img></div>
                <div class="shujuTableCls">
                    <div class="jdsjCls">
                        <div>种植总面积</div>
                        <div>{{info.jidiBigdataTotalPlantingArea || 0}}<span>亩</span></div>
                    </div>
                    <div class="splitCls"></div>
                    <div class="jdsjCls">
                        <div>种植地块数</div>
                        <div>{{info.jidiBigdataNumberOfPlantingPlots || 0}}<span>块</span></div>
                    </div>
                    <div class="splitCls"></div>
                    <div class="jdsjCls">
                        <div>种植品种数</div>
                        <div>{{info.jidiBigdataNumberOfPlantingVarieties || 0}}<span>类</span></div>
                    </div>
                </div>
                <div class="shujuTableCls">
                    <div class="jdsjCls">
                        <div>技术人员数</div>
                        <div>{{info.jidiBigdataNumberOfTechnicians || 0}}<span>人</span></div>
                    </div>
                    <div class="splitCls"></div>
                    <div class="jdsjCls">
                        <div>管理人员数</div>
                        <div>{{info.jidiBigdataNumberOfManagementPersonnel || 0}}<span>人</span></div>
                    </div>
                    <div class="splitCls"></div>
                    <div class="jdsjCls">
                        <div>农机具台</div>
                        <div>{{info.jidiBigdataNumberOfAgriculturalMachinery || 0}}<span>套</span></div>
                    </div>
                </div>
                <div class="btfusCls">
                    <div class="zzmjBoxCls">
                        <div class="boxTitleCls">种植面积<img src="../../../assets/images/dataScreen/boxTitleBgs.png"></img>
                        </div>
                        <div class="jisuifBgCls">
                            <div>{{info.plantingAreaAttr1}}</div>
                            <div>{{info.plantingAreaValue1}}<span>{{info.plantingAreaUnit1}}</span></div>
                        </div>
                        <div class="jisuifBgCls">
                            <div>{{info.plantingAreaAttr2}}</div>
                            <div>{{info.plantingAreaValue2}}<span>{{info.plantingAreaUnit2}}</span></div>
                        </div>
                        <div class="jisuifBgCls">
                            <div>{{info.plantingAreaAttr3}}</div>
                            <div>{{info.plantingAreaValue3}}<span>{{info.plantingAreaUnit3}}</span></div>
                        </div>
                    </div>
                    <div class="yugunianzongliangBoxCls">
                        <div class="boxTitleCls">预估年总产量 | 平均亩产<img
                                src="../../../assets/images/dataScreen/boxTitleBgs.png"></img></div>
                        <div class="jisuif2BgCls" v-for="(item, i) in datascreen11AnnualTotalYieldBOList" :key="i">
                            <div>{{item.parcelName}}</div>
                            <div>{{item.totalOutput}}公斤</div>
                            <div>|</div>
                            <div>{{item.averageYield}}公斤/亩</div>
                        </div>

                    </div>

                </div>
                <div class="boxTitleCls">基地实时画面<img src="../../../assets/images/dataScreen/boxTitleBgs.png"></img></div>
                <div class="shujuTableCls" v-if="isTrue">
                    <div class="vedioCls"  v-for="(item, i) in info.deviceEnterpriseRelationBOS" :key="i">
                        <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="webcamAttribute" :flv="item.deviceUrl" />
                        <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="webcamAttribute" :flv="item.deviceUrl"/>
                        <!-- <EZUIKitJs2 :msg="'video1'" :title="video1Title" :attribute="webcamAttribute"
                            :flv="info.jidiVedio1Url" /> -->

                        <div class="vedioTitleCls" @click="transitionFun(item.deviceUrl, item.installArea, item.deviceFactory)">{{item.installArea}}</div>
                    </div>
                    <!-- <div class="vedioCls">
                        <EZUIKitJs2 :msg="'video2'" :title="video2Title" :attribute="webcamAttribute"
                            :flv="info.jidiVedio2Url" />

                        <div class="vedioTitleCls">{{info.jidiVedio2Title}}</div>
                    </div> -->
                </div>
            </div>
            <div class="jdsshmCls" style="width:938px;margin-left: 63px;padding: 25px 8px;">
                <div class="jdssJ1Cls"></div>
                <div class="jdssJ2Cls"></div>
                <img :src="info.vedio3dUrl" class="guwuzaliangzhongdituCls"></img>

            </div>

            <div class="jdsshmCls" style="margin-left: 63px;width: 1075px;height: 1420px;">
                <div class="jdssJ1Cls"></div>
                <div class="jdssJ2Cls"></div>
                <div style="height:10px"></div>
                <div class="boxTitleCls">基地气象站<img src="../../../assets/images/dataScreen/boxTitleBgs.png"></div>

                <div class="qixiangzhanCls">
                    <div class="wendubiaoCls">{{qxList.ambientTemperature ? parseInt(qxList.ambientTemperature) : '--'}}</div>
                    <div class="wuliansbCls">
                        <div class="wlsbRowCls">
                            <div class="wlsb1IconCls">风速</div>
                            <div>
                                <span class="status1Cls"></span>
                                <span>{{qxList.windSpeed ? qxList.windSpeed : '--'}}</span>
                                <span>m/s</span>
                            </div>
                        </div>
                        <div class="wlsbRowCls">
                            <div class="wlsb2IconCls">湿度</div>
                            <div>
                                <span class="status1Cls"></span>
                                <span>{{qxList.ambientHumidity ? (Number(qxList.ambientHumidity)).toFixed(0) : '--'}}</span>
                                <span>%Rh</span>
                            </div>
                        </div>
                        <div class="wlsbRowCls">
                            <div class="wlsb3IconCls">雨量</div>
                            <div>
                                <span class="status2Cls"></span>
                                <span>{{qxList.rainfall ? qxList.rainfall : 0}}</span>
                                <span>mm</span>
                            </div>
                        </div>
                        <div class="wlsbRowCls">
                            <div class="wlsb4IconCls">辐射</div>
                            <div>
                                <span class="status1Cls"></span>
                                <span>{{qxList.totalRadiation ? qxList.totalRadiation : '--'}}</span>
                                <span>HW/m²</span>
                            </div>
                        </div>
                        <div class="wlsbRowCls">
                            <div class="wlsb5IconCls">光照</div>
                            <div>
                                <span class="status1Cls"></span>
                                <span>{{qxList.lightIntensity ? qxList.lightIntensity : '--'}}</span>
                                <span>Lux</span>
                            </div>
                        </div>
                        <div class="wlsbRowCls">
                            <div class="wlsb6IconCls">大气压</div>
                            <div>
                                <span class="status1Cls"></span>
                                <span>{{qxList.pressure ? qxList.pressure : '--'}}</span>
                                <span>hPa</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="boxTitleCls" style="margin-top:30px">基地土壤指标监测<img
                        src="../../../assets/images/dataScreen/boxTitleBgs.png"></img></div>

                <div style="height:50px"></div>
                <div class=" zbsjiyeuCls">
                    <div class="qixiangzbsjCls1">地块</div>
                    <div class="qixiangzbsjCls2">土壤PH值</div>
                    <div class="qixiangzbsjCls3">土壤温度</div>
                    <div class="qixiangzbsjCls4">土壤水分</div>
                    <div class="qixiangzbsjCls5">土壤盐分</div>
                    <div class="qixiangzbsjCls6">土壤电导率</div>
                </div>

                <list-scroll style="height: 180px;" :speed="1" v-if="isDkShow">
                                <div class="list">
                                    <div class="zbsjiyeuDataCls" v-for="(item, index) in trList" :key="index">
                                        <div class="zbsjiyeuDataValCls1">{{item.key}}</div>
                                        <div class="zbsjiyeuDataValCls2">{{item.iotDataBO.ph ? item.iotDataBO.ph : '--'}}</div>
                                        <div class="zbsjiyeuDataValCls3">{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</div>
                                        <div class="zbsjiyeuDataValCls4">{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</div>
                                        <div class="zbsjiyeuDataValCls5">{{item.iotDataBO.salt != null && Number(item.iotDataBO.salt) > 0 ? item.iotDataBO.salt : 48}}ppm</div>
                                        <div class="zbsjiyeuDataValCls6">{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : 96}}uS/cm</div>
                                    </div>
                                </div>
                            </list-scroll>
                <!-- <div id="tr11yyp" style="height: 180px; overflow: hidden;">
                    <div id="tr11yyp1">
                        <div class="zbsjiyeuDataCls" v-for="(item, index) in trList" :key="index">
                            <div class="zbsjiyeuDataValCls1">{{item.key}}</div>
                            <div class="zbsjiyeuDataValCls2">{{item.iotDataBO.ph ? item.iotDataBO.ph : '--'}}</div>
                            <div class="zbsjiyeuDataValCls3">{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</div>
                            <div class="zbsjiyeuDataValCls4">{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</div>
                            <div class="zbsjiyeuDataValCls5">{{item.iotDataBO.salt != null && Number(item.iotDataBO.salt) > 0 ? item.iotDataBO.salt : 48}}ppm</div>
                            <div class="zbsjiyeuDataValCls6">{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : 96}}uS/cm</div>
                        </div>
                    </div>
                    
                    <div v-if="isYes">
                        <div class="zbsjiyeuDataCls" v-for="(item, index) in trList" :key="index">
                            <div class="zbsjiyeuDataValCls1">{{item.key}}</div>
                            <div class="zbsjiyeuDataValCls2">{{item.iotDataBO.ph ? item.iotDataBO.ph : '--'}}</div>
                            <div class="zbsjiyeuDataValCls3">{{item.iotDataBO.soilTemp ? item.iotDataBO.soilTemp : '--'}}℃</div>
                            <div class="zbsjiyeuDataValCls4">{{item.iotDataBO.soilHumi ? item.iotDataBO.soilHumi : '--'}}%</div>
                            <div class="zbsjiyeuDataValCls5">{{item.iotDataBO.salt != null && Number(item.iotDataBO.salt) > 0 ? item.iotDataBO.salt : 48}}ppm</div>
                            <div class="zbsjiyeuDataValCls6">{{item.iotDataBO.soilCond ? item.iotDataBO.soilCond : 96}}uS/cm</div>
                        </div>
                    </div>
                </div> -->
                
            

                <div class="zuihouCls">
                    <div class="zuihRightCls">
                        <div class="boxTitleCls">农事预警<img src="../../../assets/images/dataScreen/boxTitleBgs.png"></img>
                        </div>
                        <div class="nsyjClsBiaoti">
                                <span>地块位置</span>
                                <span>实际检测值</span>
                                <span>预警详情</span>
                                <span>预警时间</span>
                            </div>
                            <!-- <marquee class="marquee-list" direction="up" style="height: 450px" behavior="scroll" scrollamount="5"> -->
                                <div id="nsyj5yyp" style="height: 220px; overflow: hidden">
                                    <div id="nsyj5yyp1">
                                        <div class="nsyjClsBiaotiCls" v-for="(item,index) in warnList" :key="index">
                                            <span>
                                                <img width="35px" src="../../../assets/images/dataScreen/baojin.png" v-if="item.isWarn==0">
                                                <img width="35px" src="../../../assets/images/dataScreen/warning.png" v-else>
                                                <span style="width:90%;padding-left:15px;">{{item.warnName}}</span>
                                            </span>
                                            <span>{{item.detectionValue}}</span>
                                            <span v-if="item.isWarn==0" style="color: #FFA800">
                                                <img width="25px" src="../../../assets/images/dataScreen/up.png">
                                                <span style="width:80%;padding-left:15px;">{{item.exceedanceName}}</span>
                                            </span>
                                            <span v-else style="color: #FF2600">
                                                <img width="25px" src="../../../assets/images/dataScreen/down.png">
                                                <span style="width:80%;padding-left:15px;">{{item.exceedanceName}}</span>                              
                                            </span>
                                            <span>{{dateFormat(item.createTime)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="isYes3">
                                        <div class="nsyjClsBiaotiCls" v-for="(item,index) in warnList" :key="index">
                                            <span>
                                                <img width="35px" src="../../../assets/images/dataScreen/baojin.png" v-if="item.isWarn==0">
                                                <img width="35px" src="../../../assets/images/dataScreen/warning.png" v-else>
                                                <span style="width:90%;padding-left:15px;">{{item.warnName}}</span>
                                            </span>
                                            <span>{{item.detectionValue}}</span>
                                            <span v-if="item.isWarn==0" style="color: #FFA800">
                                                <img width="25px" src="../../../assets/images/dataScreen/up.png">
                                                <span style="width:80%;padding-left:15px;">{{item.exceedanceName}}</span>
                                            </span>
                                            <span v-else style="color: #FF2600">
                                                <img width="25px" src="../../../assets/images/dataScreen/down.png">
                                                <span style="width:80%;padding-left:15px;">{{item.exceedanceName}}</span>                              
                                            </span>
                                            <span>{{dateFormat(item.createTime)}}</span>
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
            </div>

            <!--<div class="jdsshmCls" style="margin-left: 50px;width: 1498px;">
                <div class="jdssJ1Cls"></div>
                <div class="jdssJ2Cls"></div>
                <div style="height:10px"></div>
                <div class="boxTitleCls">种植日志<img src="../../../assets/images/dataScreen/boxTitleBgs.png"></img></div>

                <div class="rizhiCls">
                    <div></div>
                    <div>
                        <div class="rizhiBoxCls mb90" v-for="(item, i) in datascreen11PlantingLogBOList" :key="i">
                            <div class="rizhizhutuCls">
                                <div>{{item.plantingDate}}</div>
                                <div>新农人：{{item.newFarmerName}}</div>
                                <div>事件：{{item.plantingEvent}}</div>
                            </div>
                            <div class="rizhizhaopianCls">
                                <div>
                                    <img :src="item.plantingEventPhotoUrl1"></img>
                                </div>
                                <div>
                                    <img :src="item.plantingEventPhotoUrl2"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="jdsshmCls" style="margin-left: 50px;width: 1716px;">
                <div class="jdssJ1Cls"></div>
                <div class="jdssJ2Cls"></div>
                <div style="height:10px"></div>
                <div class="boxTitleCls">一升小米<img src="../../../assets/images/dataScreen/boxTitleBgs.png"></img></div>
                <div class="yishengxiaomiCls">一升小米品种介绍</div>
                <div class="yishengxiaomiJieshaoCls">
                    {{info.milletVarietiesDesc}}
                </div>
                <div class="yishengxiaomiCls">一升小米订单统计</div>

                <div class="orderTableCls">
                    <div class="ysmiHeadCls">
                        <div>姓名</div>
                        <div>联系方式</div>
                        <div>预订数量</div>
                        <div>下单时间</div>
                    </div>
                </div>

                <marquee style=" height: 500px;overflow: hidden;" direction="up" behavior="scroll" scrollamount="5">
                    <div class="orderTableCls">
                        <div class="ysmiRowCls" v-for="(item, i) in datascreen11OrderBOList" :key="i">
                            <div>{{item.buyerName}}</div>
                            <div>{{item.buyerTel}}</div>
                            <div>{{item.scheduledQuantity}}斤</div>
                            <div>{{item.orderTime}}</div>
                        </div>
                    </div>
                </marquee>

                <div class="zsboxCls">
                    <div>
                        <div class="zsTitleCls">小米营养成分检测报告</div>
                        <div class="zsFileCls">
                            <div>
                                <img :src="info.nutritionCompositionTestReportUrl1"></img>
                            </div>
                            <div>
                                <img :src="info.nutritionCompositionTestReportUrl2"></img>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="zsTitleCls">土壤监测报告</div>
                        <div class="zsFileCls">
                            <div>
                                <img :src="info.soilMonitoringReportUrl1"></img>
                            </div>
                            <div>
                                <img :src="info.soilMonitoringReportUrl2"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="gzbsCls">
                    <div>一物一码</div>
                    <div>物联网监控</div>
                    <div >
                        <img class="erCode" :src="info.milletQrcodeUrl" alt="">
                        小米H5</div>
                    <div>原产地认证</div>
                    <div>品牌打造</div>
                </div>

            </div>-->
        </div>

        <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">
                        

                    </div>
                    <div class="video-centent">
                        <div id="video111"></div>
                        <div class="video-title">{{vedioTitle}}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import EZUIKit from "ezuikit-js";
import cyberplayer from "../../../../static/cyberplayer.js"
import ListScroll from "../../../components/listScroll.vue";
import 'animate.css';
export default {
    name: '',
    components: {
        EZUIKitJs,
        EZUIKitJs2,
        ListScroll
    },
    data() {
        return {
            warnList: [],
            info: null,
            isChoose: 1,
            //预估年总产量 | 平均亩产
            datascreen11AnnualTotalYieldBOList: [],
            //一升小米统计
            datascreen11OrderBOList: [],
            isTrue: false,
            isShow: false,
            show: false,
            //种植日志
            //农事预警
            qxList: {},
            trList: [],
            potatoData: [],
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12,
            datascreen11WarnBOList: [],
            datascreen11PlantingLogBOList: [],
            webcamAttribute: {
                width: 439,
                height: 365
            },
            timer1: null,
            timer2: null,
            video1Title: '1',
            video2Title: '2',
            weiyangRecordList: [],
            day7List: [],
            currDay: 0,
            timeNum: 0,
            show: false,
            vedioTitle: "",
            player: null,
            domHeight: 0,
            domHeight2: 0,
            domHeight3: 0,
            isYes: false,
            isYes2: false,
            isYes3: false,
            isDkShow: false
        }
    },
    props: {
        _timeFmt: ""
    },
    computed: {
        weiyangTimeCompute() {
            return (_timeFmt) => {
                var rds = _timeFmt.split(":");
                var fenCounts = parseInt(rds[0]) * 60 + parseInt(rds[1]);

                //left: 13501px;
                // console.log(fenCounts/1440*1440);
                return fenCounts - 104;
            }
        }
    },
    mounted() {

        this.doPlay("");
        var timer = setInterval(() => {
            ++this.timeNum
            console.log(this.timeNum)
            if (this.timeNum > 4) {
                clearInterval(timer)
            }
        }, 50);

        //获取喂养时间数据
        this.getWeiyangRecords();
        //获取前7日
        this.getday7List();

        this.getInfo();
        this.iot();
        setInterval(() => {
            this.iot();
        },60000)

    },
    methods: {
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/gwzl/tubiao.png"), new BMap.Size(96, 68), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(10, 25),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].xAxis, this.potatoData[i].yAxis);   
                var marker = this.potatoData[i].id == 2 ? new BMap.Marker(point, {icon: myIcon}) : '';   
                map.addOverlay(marker);   
                var content = this.potatoData[i].id == 2 ? '<div style="font-size: 30px; line-height: 86px">'+this.potatoData[i].baseName +'</div><div style="text-align: center; line-height: 100px">'+this.potatoData[i].acres+'亩</div>' : '<div style="position: relative; height: 100%;"><div style="font-size: 30px; line-height: 86px; position: absolute; bottom: -146px; left: -45px; color: #49e9d9; text-align: left">'+this.potatoData[i].baseName +'</div><div style="text-align: center; line-height: 100px">'+this.potatoData[i].acres+'亩</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-60, -220)
                })  
            
                map.addOverlay(label);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '30px',
                    border: '0',
                    // backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-07/2533a5d0ddaf4c41a19a77af74d9c9b8cbba9c3c4ccf8592d32452e823767362.png")',
                    backgroundImage: this.potatoData[i].id == 2 ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-07/2533a5d0ddaf4c41a19a77af74d9c9b8cbba9c3c4ccf8592d32452e823767362.png")' : 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',           
                    backgroundColor: 'transparent',
                    height: this.potatoData[i].id == 2 ? '236px' : '153px',
                    padding: '10px 30px 41px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            // var marker = new BMap.Marker(point) // 创建标注
            // map.addOverlay(marker) // 将标注添加到地图中
            // var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            // map.addOverlay(circle)
            map.enableScrollWheelZoom(true);
        },
        chooseTab(type){
            this.isChoose = type;
        },
        iot(){
            this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '11'}).then(res => {
                
                let newArr = [];
                    for(let i = 0; i < res.data.length; i++) {
                        if(!res.data[i].iotDataBO) {
                            res.data[i].iotDataBO = {};
                        }
                        newArr.push(res.data[i]);
                    }
                 if (localStorage.getItem('weather9') == null) {
                        localStorage.setItem('weather9', JSON.stringify(res.data));
                        this.qxList = res.data[0].iotDataBO;
                    } else {
                        let oldArr = JSON.parse(localStorage.getItem('weather9'));
                        localStorage.setItem('weather9', JSON.stringify(res.data));                    
                        oldArr.forEach((item,index) => {
                            newArr.forEach((items,idx) => {
                                if (item.key == items.key) {
                                    items.iotDataBO.rainfall = (Number(items.iotDataBO.rainfall) - Number(item.iotDataBO.rainfall)).toFixed(2);
                                }
                            })
                        });
                        this.qxList = newArr[0].iotDataBO;                                  
                    }
                // this.qxList = res.data;
   
            })
            this.qa.queryIotDataByDate({deviceType: 'SENSOR_TR', datascreenNo: '11'}).then(res => {
                
                    for(let i = 0; i < res.data.length; i++) {
                        if(!res.data[i].iotDataBO) {
                            res.data[i].iotDataBO = {};
                        }
                    }
                    this.trList = res.data;
                    this.isDkShow = true;
                // this.qxList = res.data;
   
            })
        },

        autoplay3(){
            var dom = document.getElementById("nsyj5yyp");
            var dom1 = document.getElementById("nsyj5yyp1");
            var that = this;
            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes3 = true;
                if(this.timer1) {
                    clearInterval(this.timer1);
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer1 = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight3 = 0;
                        dom.scrollTop = that.domHeight3;
                    } else {
                        that.domHeight3++;
                        dom.scrollTop = that.domHeight3;
                    }
                }, 10)
            }
        },
        autoplay(){
            var dom = document.getElementById("tr11yyp");
            var dom1 = document.getElementById("tr11yyp1");

            var that = this;
            console.log(dom1.offsetHeight)
            console.log(dom.offsetHeight)
            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes = true;
                if(this.timer2) {
                    clearInterval(this.timer2);
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer2 = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight = 0;
                        dom.scrollTop = that.domHeight;
                    } else {
                        that.domHeight++;
                        dom.scrollTop = that.domHeight;
                    }
                }, 20)
            }
        },
        getInfo() {
            this.qa.datascreen5WarnQueryForPageLists({datascreen3EnterpriseId: '9999'}).then(res => {
                this.warnList = res.data;

                setTimeout(() => {
                    this.autoplay3();
                },1000)
            })
            this.qa.queryDatascreen11ById({}).then(res => {
                if(res.respCode == '0000') {
                    this.info = res.data
                    this.isTrue = true;
                    if(res.data.datascreen11AnnualTotalYieldBOList != null) {
                        this.datascreen11AnnualTotalYieldBOList = res.data.datascreen11AnnualTotalYieldBOList
                    }
                    if(res.data.datascreen11OrderBOList != null) {
                        this.datascreen11OrderBOList = res.data.datascreen11OrderBOList;
                    }
                    if(res.data.datascreen11PlantingLogBOList != null) {
                        this.datascreen11PlantingLogBOList = res.data.datascreen11PlantingLogBOList;
                    }

                    if(res.data.datascreen11WarnBOList != null) {
                        this.datascreen11WarnBOList = res.data.datascreen11WarnBOList;
                    }
                }
                console.log(res.respCode)
            })

            this.qa.datascreen18WorkMapqueryForList({}).then(res => {
                this.potatoData = res.data;
                this.isShow = true;
            })
        },
        dateFormat(dateStr){
            var date = new Date(dateStr);
            console.log(date)
            var year = date.getFullYear();
            var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1): date.getMonth() + 1;
            var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return year + '-' + month + '-' + day;
        },
        changeWeiyangDay(_day) {
            this.currDay = _day;
        },
        getday7List() {
            this.currDay = new Date().getDate();
            this.day7List = [];
            for (let i = 6; i >= 0; i--) {
                this.day7List.push(this.currDay - i);
            }
        },
        getWeiyangRecords() {
            this.weiyangRecordList = ["5:00", "12:00", "24:00"];
        },
        goDataScreen2() {
            this.$emit('tabsTypeClick', '2');
        },
        doPlay(_url) {
            this.qa.getAccessToken({}).then(res => {
                this.player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video111",
                    accessToken: res.data.accessToken,
                    url: _url,
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 3080,
                    height: 1560
                });
            });
        },
        doPlay2(_url){
            this.$nextTick(() => {
                 var player = cyberplayer('video111').setup({
                    width: 3080, // 宽度，也可以支持百分比（不过父元素宽度要有）
                    height: 1560, // 高度，也可以支持百分比
                    title: '111', // 标题
                    isLive: true, // 必须设置，表明是直播视频
                    file: _url, // //您的视频源的地址（目前是乐橙示例播放地址）
                    image: '', // 预览图
                    autostart: true, // 是否自动播放
                    stretching: "uniform", // 拉伸设置
                    repeat: true, // 是否重复播放
                    volume: 0, // 音量，注：仅当用户同意、网站由用户激活或媒体无声时允许自动播放
                    controls: true, // 是否显示控制栏
                    hls: {
                        reconnecttime: 5 // hls直播重连间隔秒数
                    },
                    ak: "a60324c9eed249a7812c629f4d10ee14" // 百度智能云平台注册（https://cloud.baidu.com）即可获得accessKey
                });
            })
           
        },
        transitionFun(_url, _title, type) {


            this.show = !this.show;
            if(type == "HK") {
                this.doPlay(_url);
            } else {
                this.doPlay2(_url);
            }
            

            
            this.vedioTitle = _title;
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
    transform: translateX(100px);
    opacity: 0;
}

.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videdo-box {
    display: flex;
    align-items: center;
}

.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}

.video-title {
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}

.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;
}

.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.dataScreenContent {
    display: flex;
    padding: 0 58px;
}

div.dsBgbox {
    background-image: url("../../../assets/images/dataScreen/ds1_bg_box.png");
    width: 337px;
    height: 120px;
    background-size: 100% 100%;
    position: relative;
    font-size: 32px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #23D1FF;
}

div.dsBgbox>div.dsIconCls {
    width: 130px;
    height: 114px;
    background: url(../../../assets/images/dataScreen/ds_icon2.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.dsIcon2Cls {
    width: 110px;
    height: 110px;
    background: url(../../../assets/images/dataScreen/dmscIcon2.png) no-repeat;
    background-size: 90% 90%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 15px;
}

div.dsBgbox>div.dsIcon3Cls {
    background: url("../../../assets/images/dataScreen/dmscIcon3.png") no-repeat;
    background-size: 85% 85%;
    background-position: 20px 10px;
    width: 100px;
    height: 110px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
div.dsBgbox>div.dsIcon4Cls {
    background: url("../../../assets/images/dataScreen/dmscIcon4.png") no-repeat;
    background-size: 85% 85%;
    background-position: 20px 10px;
    width: 110px;
    height: 110px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
div.dsBgbox>div.dsIcon5Cls {
    background: url("../../../assets/images/dataScreen/dmscIcon3.png") no-repeat;
    background-size: 85% 85%;
    background-position: 20px 10px;
    width: 110px;
    height: 110px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.numCls {
    width: 100%;
    margin-left: 130px;
    padding-top: 20px;
}

div.dsBgbox>div.titleCls {
    width: 100%;
    /*height: 228px;*/
    margin-left: 135px;
    font-size: 20px;
    color: #FFFFFF;
}

div.numCls>span.dwCls {
    font-size: 20px;
}
.map-box {
  position: relative;
  width: 1671px;
  height: 1420px;
  background: #0E1839;
  border: 5px solid #113571;
  margin-top: 99px;
  margin-right: 83px;
}
.map-box-title {
  width: 1671px;
  height: 96px;
  display: flex;
  align-items: center;
  padding-left: 54px;
  color: #99B3DB;
  border-bottom: 1px solid #0F2E5F;
}
.map-box-titleImg {
  width: 35px;
  height: 35px;
  background: url(../../../assets/images/dataScreen/title-logo.png) no-repeat;
  background-size: 100% 100%;
  margin-right: 39px;
}
.map-box-titleText {
  margin-right: 97px;
  font-size: 30px;
  position: relative;
  z-index: 10;
  cursor: pointer;
}
.chooseTitle {
    color: #fff !important;
}
.map-box-titleText >span {
    display: inline-block;
    width: 100%;
    height: 10px;
    background: rgba(26, 110, 247, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.map-box-bottom {
  display: flex;
  justify-content: flex-start;
  padding-left: 54px;
}
.tjCls {
    position: relative;
    z-index: 10;
}
div.tjCls>div:nth-child(n+1) {
    margin-top: 50px;
}

div.mapCls {
    width: 1658px;
    height: 1320px;
    background: url(../../../assets/images/dataScreen/map.png) no-repeat;
    background-size: 100% 100%;
    margin-left: -80px;
    position: absolute; 
}

div.mapCls>div.mapTips2Cls {
    position: absolute;
    top: 2060px;
    left: 620px;
    background-image: url("../../../assets/images/dataScreen/ji-2.png");
    width: 518px;
    height: 214px;
    text-align: right;
    font-weight: bolder;

}

div.mapCls>div.mapTips2Cls>div {
    font-size: 36px;
    position: absolute;
    right: 0px;
    width: 280px;
    height: 90px;
    line-height: 90px;
    text-align: left;
    padding-left: 40px;

}

div.mapCls>div.yangCls {
    position: absolute;
    top: 1700px;
    left: 2000px;
    background-image: url("../../../assets/images/dataScreen/guwuzaliangMapPoint.png");
    background-size: cover;
    width: 751px;
    height: 435px;
    padding-top: 45px;
    font-weight: bolder;
    padding-left: 100px;

}

div.mapCls>div.yangCls>div:nth-child(1) {
    font-size: 50px;

}

div.mapCls>div.yangCls>div:nth-child(2) {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 50px;
}

div.mapCls>div.yangCls>div:nth-child(3) {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 20px;
}

div.dsBox3 {
    margin-left: 400px;
    float: left;
    width: 3096px;
    height: 100%;
}

div.dsBox3>div.yzcyCls {
    float: left;
    width: 3096px;
    height: 1037px;
    background: #0E1639;
    border: 5px solid #123171;
    position: relative;
    padding: 85px;
}

div.dsBox3>div.yzcy2Cls {
    margin-top: 60px;
    float: left;
    width: 3096px;
    height: 1816px;
    background: #0E1639;
    border: 5px solid #123171;
    position: relative;
    padding: 85px;
}

div.ysStCls {
    background-image: url("../../../assets/images/dataScreen/ysSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    top: -8px;
    left: -8px;

}

div.zxStCls {
    background-image: url("../../../assets/images/dataScreen/zxSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    bottom: -8px;
    right: -8px;

}

div.yzcyCls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzcy2Cls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzTableHeaderCls {
    margin-top: 40px;
}

div.yzTableHeaderCls>div {
    float: left;
    color: #A4DF11;
    font-size: 59px;
}

div.yzTableHeaderCls>div:nth-child(1),
div.yzTableRowCls>div:nth-child(1) {
    width: 300px;
}

div.yzTableHeaderCls>div:nth-child(2),
div.yzTableRowCls>div:nth-child(2) {
    width: 250px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(3),
div.yzTableRowCls>div:nth-child(3) {
    width: 275px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(4),
div.yzTableRowCls>div:nth-child(4) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(5),
div.yzTableRowCls>div:nth-child(5) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(6),
div.yzTableRowCls>div:nth-child(6) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(7),
div.yzTableRowCls>div:nth-child(7) {
    width: 300px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(8),
div.yzTableRowCls>div:nth-child(8) {
    width: 275px;
    text-align: right;
}

div.yzTableRowCls>div {
    height: 120px;
    line-height: 120px;
    float: left;
    color: #00FB40;
    font-size: 59px;
}

.marquee-list {
    height: 600px;
    overflow: hidden;
}

.marquee-list2 {
    height: 1600px;
    overflow: hidden;
}

div.yzcy3Cls {
    width: 5369px;
    height: 2902px;
    margin-left: 100px;
    float: left;
    position: relative;
    background-color: #01003d;
}

div.yzcy3Cls>div.ys1Cls {
    background-image: url("../../../assets/images/dataScreen/jx1.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    left: 0;
}

div.yzcy3Cls>div.ys2Cls {
    background-image: url("../../../assets/images/dataScreen/jx2.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    right: 0;
}

div.yzcy3Cls>div.ys3Cls {
    background-image: url("../../../assets/images/dataScreen/jx3.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    right: 0;
}

div.yzcy3Cls>div.ys4Cls {
    background-image: url("../../../assets/images/dataScreen/jx4.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    left: 0;
}

div.paibianCls {
    background-image: url("../../../assets/images/dataScreen/paibian.png");
    width: 1638px;
    height: 118px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

div.yzcy3Cls>div.mapsCls {
    background-image: url("../../../assets/images/dataScreen/map.png");
    width: 2981px;
    height: 2881px;
    background-size: cover;
    margin-left: 200px;
    float: left;
    position: relative;

}

div.jaigepai1Cls {
    position: absolute;
    top: 350px;
    left: 1100px;
}

div.jaigepai2Cls {
    position: absolute;
    top: 1320px;
    left: 200px;
}

div.jaigepai3Cls {
    position: absolute;
    top: 1220px;
    left: 980px;
}

div.jaigepai4Cls {
    position: absolute;
    top: 1090px;
    left: 1970px;
}

div.jaigepai5Cls {
    position: absolute;
    top: 1550px;
    left: 1470px;
}

div.jaigepai6Cls {
    position: absolute;
    top: 2100px;
    left: 1950px;
}

div.gjiageCls {
    background-image: url("../../../assets/images/dataScreen/g448.png");
    width: 295px;
    height: 105px;
    line-height: 105px;
    text-align: center;
    font-size: 45px;

}

div.gjiageCls>span {
    font-size: 65px;
}

div.yzcy3Cls {

    overflow: hidden;
}

div.textListBoxCls {
    float: left;
    width: 1950px;
    height: 100%;
    margin-top: 200px;
    padding-left: 100px;

}

div.textListBoxCls>div.rouyangTableCls {
    width: 1950px;
    height: 1690px;

}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div {
    float: left;
    background-color: #00c29a;
    color: #fff;
    font-size: 45px;
    height: 80px;
    line-height: 80px;
}

div.textListBoxCls>div.rouyangTableCls>marquee>div.bodyCls>div.rowCls>div {
    float: left;
    color: #3de2d6;
    font-size: 45px;
    height: 80px;
    line-height: 80px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(1),
div.bodyCls>div.rowCls>div:nth-child(1) {
    width: 400px;
    text-indent: 50px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(2),
div.bodyCls>div.rowCls>div:nth-child(2) {
    width: 650px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(3),
div.bodyCls>div.rowCls>div:nth-child(3) {
    width: 300px;
    text-align: center;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(4),
div.bodyCls>div.rowCls>div:nth-child(4) {
    width: 250px;
    text-align: center;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(5),
div.bodyCls>div.rowCls>div:nth-child(5) {
    width: 340px;
}

div.bodyCls>div.rowCls>div:nth-child(5) {
    padding-right: 40px;
}

div.textListBoxCls>div.rouyangTableCls>marquee>div.bodyCls>div.rowCls:nth-child(2n+1)>div {
    background: rgba(73, 232, 217, 0.2);
}

div.tubiaoCls {
    margin-top: 50px;
}

div.zhoushititleCls {
    font-size: 74px;
    margin-bottom: 50px;
}

div.zoushituCls {
    background-image: url("../../../assets/images/dataScreen/111.png");
    width: 1801px;
    height: 674px;
}

div.main3cls {
    height: 100%;
    display: flex;
    margin-top: 99px;
    color: #fff;
}

div.main3cls>div.jimain3Cls {
    width: 8548px;
    margin-left: 800px;
    margin-bottom: 100px;
    height: 130px;
    font-size: 60px;
    text-align: center;
    background-image: url("../../../assets/images/dataScreen/jimain3.png");
    background-repeat: no-repeat;
    background-position: center bottom;


}

div.jdsshmCls {
    width: 1074px;
    height: 1420px;
    float: left;
    background: #0E1639;
    border: 2px solid #123171;
    position: relative;
    padding: 40px;
}

div.jdssJ1Cls {
    position: absolute;
    background-image: url("../../../assets/images/dataScreen/ysSt-jiao.png");
    width: 32px;
    height: 32px;
    top: -7px;
    left: -7px;
}

div.jdssJ2Cls {
    position: absolute;
    background-image: url("../../../assets/images/dataScreen/zxSt-jiao.png");
    width: 32px;
    height: 32px;
    bottom: -7px;
    right: -7px;

}

div.jdsshmCls>div.jdssTitleCls {
    background-image: url("../../../assets/images/dataScreen/malinshu-title.png");
    width: 1164px;
    height: 148px;
    line-height: 148px;
    font-size: 55px;
    padding-left: 55px;
}

div.main3cls>div.jdtj2Cls {
    width: 1501px;
    height: 100%;
    float: left;
    margin-left: 53px;
}

div.jdtj2Cls>div.jdtj2_1Cls {
    width: 100%;
    height: 1379px;
    position: relative;
    background: #091A38;
    border: 2px solid #1585D5;

}

div.jdtj2Cls>div.jdtj2_1Cls.secCls {
    margin-top: 50px;
    height: 1352px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jdtjTitleBgCls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/tjTitleBg.png");
    width: 1497px;
    height: 139px;
    text-align: center;
    font-size: 48px;
    line-height: 139px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jiao1Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao1.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    left: -6px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jiao2Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao2.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    right: -6px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jiao3Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao3.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    right: -6px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jiao4Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao4.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    left: -6px;
}

div.qnklxhltjCls {
    width: 100%;
    font-size: 45px;
    text-align: center;
    height: 100px;
    line-height: 100px;
}

div.qnklxhltjBoxCls {
    width: 100%;
    height: 500px;
    padding-left: 40px;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls {

    background-size: 242px 195px;
    background-image: url("../../../assets/images/dataScreen/zhibiaoBg.png");
    width: 242px;
    height: 195px;
    float: left;
    margin: 20px;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(1) {
    font-size: 60px;
    font-family: DFPLiJinHeiW8-GB;
    font-weight: 400;
    text-align: center;
    padding-top: 20px;
    color: #00D3DB;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(1)>span {
    font-size: 40px;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(2) {
    font-size: 36px;
    padding-top: 20px;
    text-align: center;
}

div.ywsjtjCls {
    height: 100px;
    font-size: 40px;
    text-align: center;
}

div.ywsjtjBoxCls {
    height: 300px;
    position: relative;
}

div.ywsjtjBoxCls>div.timerBarCls {
    position: absolute;
    width: 1440px;
    height: 107px;
    background: #0f2f5c;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
}

div.ywsjtjBoxCls>div.timerBarCls>span {
    flex: 1;
    font-size: 30px;
    display: block;
    line-height: 107px;
    text-align: right;
}

div.ywsjtjBoxCls>div.weishiRecordCls {
    margin-top: 30px;
    background-size: 45px 91px;
    background-position: center top;
    background-image: url("../../../assets/images/dataScreen/weishiRecBg.png");
    background-repeat: no-repeat;
    position: absolute;
    color: #dfcc50;
    font-size: 30px;
    height: 200px;
    padding-top: 100px;
    position: absolute;

}

div.dayBarCls {
    height: 97px;
    line-height: 97px;
    width: 1440px;
    margin-left: 30px;
    display: flex;
}

div.dayBarCls>div {
    float: left;
    font-size: 30px;
    flex: 1;
    text-align: center;
    border: 3px solid #07375A;
    cursor: pointer;
}

div.dayBarCls>div:nth-child(n+2) {
    margin-left: -3px;
}

div.dayBarCls>div.seled {
    background: #3DA1FF;
}

div.jdtj3Cls {
    width: 2121px;
    height: 100%;
    margin-left: 50px;
    float: left;
}

div.jdtj3Cls>div.paihangbanCls {
    background-image: url("../../../assets/images/dataScreen/bsyBg.png");
    background-repeat: no-repeat;
    background-size: 2100px 247px;
    background-position: center bottom;
    width: 2121px;
    height: 600px;
    display: flex;

}

div.jdtj3Cls>div.paihangbanCls>div.ban1Cls {
    background-image: url("../../../assets/images/dataScreen/ban1.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}

div.jdtj3Cls>div.paihangbanCls>div.ban2Cls {
    background-image: url("../../../assets/images/dataScreen/ban2.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}

div.jdtj3Cls>div.paihangbanCls>div.ban3Cls {
    background-image: url("../../../assets/images/dataScreen/ban3.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}

div.jdtj3Cls>div.paihangbanCls>div.ban4Cls {
    background-image: url("../../../assets/images/dataScreen/ban4.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}

div.jdtj3Cls>div.paihangbanCls>div>div {
    font-size: 40px;
    text-align: center;
}

div.jdtj3Cls>div.paihangbanCls>div>div:nth-child(1) {
    font-size: 80px;
    font-weight: bolder;
    margin-top: 110px;
}

div.jdtj3Cls>div.paihangbanCls>div>div:nth-child(2) {}

div.jdtj3Cls>div.paihangbanCls>div>div:nth-child(1).hsCls {
    color: #ffda31;
}

div.jdtj3Cls>div.paihangbanCls>div>div:nth-child(1).lanCls {
    color: #00eafe;
}

div.moxingCls {
    background-color: #091a34;
    width: 2121px;
    height: 1229px;
    margin-top: 40px;
}

.video {
    width: 2120px;
    height: 1229px;
}


div.jdtj3Cls>div.jdjhtjCls {
    width: 100%;
    float: left;
    height: 874px;
    margin-top: 40px;
    background: #091a34;
    border: 2px solid #1585D5;
    position: relative;

}

div.jdtj3Cls>div.jdjhtjCls>div.jiao1Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao1.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    left: -6px;
}

div.jdtj3Cls>div.jdjhtjCls>div.jiao2Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao2.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    right: -6px;
}

div.jdtj3Cls>div.jdjhtjCls>div.jiao3Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao3.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    right: -6px;
}

div.jdtj3Cls>div.jdjhtjCls>div.jiao4Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao4.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    left: -6px;
}

div.jdtj3Cls>div.jdjhtjCls>div.jdtjTitleBgCls {

    background-size: cover;
    background-position: center center;
    background-image: url("../../../assets/images/dataScreen/tjTitleBg.png");
    width: 100%;
    height: 139px;
    text-align: center;
    font-size: 48px;
    line-height: 139px;
}

div.sikuangBoxCls {
    width: 100%;
    float: left;
    background: #091a34;
    border: 2px solid #1585D5;
    position: relative;
}

div.titleBgCls {

    background-size: cover;
    background-position: center center;
    background-image: url("../../../assets/images/dataScreen/tjTitleBg.png");
    height: 139px;
    line-height: 139px;
    text-align: center;
    font-size: 48px;
}

div.sikuangBoxCls>div.jiao1Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao1.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    left: -6px;
}

div.sikuangBoxCls>div.jiao2Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao2.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    right: -6px;
}

div.sikuangBoxCls>div.jiao3Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao3.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    right: -6px;
}

div.sikuangBoxCls>div.jiao4Cls {
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao4.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    left: -6px;
}

div.jdtj4Cls {
    width: 1500px;
    height: 100%;
    float: left;
    margin-left: 40px;
}

div.wenduBoxCls {

    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/wenduBg.png");
    width: 484px;
    height: 488px;
    margin-top: 40px;
    margin-left: 80px;
    text-align: center;
    padding-top: 140px;
    font-size: 130px;
    font-weight: bolder;
    float: left;
}

div.wulianwangValueBoxCls {
    float: left;
    font-size: 50px;

}

div.wulianwangValueBoxCls>div {
    height: 90px;
    line-height: 90px;
    width: 800px;
    text-indent: 70px;
    background-size: 50px 50px;
    background-repeat: no-repeat;
}

div.wulianwangValueBoxCls>div>span:nth-child(1) {
    display: block;
    float: left;
}

div.wulianwangValueBoxCls>div>span:nth-child(2) {
    display: block;
    float: right;
}

div.wulianwangValueBoxCls>div:nth-child(1) {
    background-position: 0px center;
    background-image: url("../../../assets/images/dataScreen/wu1.png");
    padding-left: 0px;
}

div.wulianwangValueBoxCls>div:nth-child(2) {
    background-image: url("../../../assets/images/dataScreen/wu2.png");
    background-position: 50px center;
    padding-left: 50px;
}

div.wulianwangValueBoxCls>div:nth-child(3) {
    background-image: url("../../../assets/images/dataScreen/wu3.png");
    background-position: 100px center;
    padding-left: 100px;
}

div.wulianwangValueBoxCls>div:nth-child(4) {
    background-image: url("../../../assets/images/dataScreen/wu4.png");
    background-position: 100px center;
    padding-left: 100px;
}

div.wulianwangValueBoxCls>div:nth-child(5) {
    background-image: url("../../../assets/images/dataScreen/wu5.png");
    background-position: 50px center;
    padding-left: 50px;
}

div.wulianwangValueBoxCls>div:nth-child(6) {
    background-image: url("../../../assets/images/dataScreen/wu6.png");
    background-position: 0px center;
    padding-left: 0px;
}

div.vedioRowCls {
    float: left;
}

div.vedioRowCls>div {
    width: 715px;
    height: 642px;
    float: left;
    margin-left: 20px;
    position: relative;
}

div.vedioColTitleCls {
    font-size: 45px;
    position: absolute;
    bottom: 0px;
    text-align: right;
    width: 100%;
    background-image: url("../../../assets/images/dataScreen/vTitleBc.png");
    height: 102px;
    line-height: 102px;
    padding-right: 20px;
}

div.jdtj5Cls {
    background-color: #091a34;
    width: 1090px;
    height: 2780px;
    float: left;
    margin-left: 40px;
    position: relative;
}

div.biaotiCls {
    background-image: url("../../../assets/images/dataScreen/kouhaoBg.png");
    background-repeat: no-repeat;
    background-size: 804px 58px;
    background-position: center center;
    height: 150px;
    width: 100%;

}

div.zhutiCls {
    font-size: 40px;
    color: #00B7EE;
}

div.zhutiCls>p {
    text-indent: 80px;
    padding-right: 40px;
    padding-left: 40px;
    text-align: justify;
    line-height: 70px;
    text-justify: distribute-all-lines;
}

div.welcomeCls {
    width: 100%;
    height: 852px;
    background-image: url("../../../assets/images/dataScreen/huanyingguanli.png");
    background-size: 985px 752px;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}

div.welcomeCls>div.tuCls {
    background-color: #00FB40;
    background-image: url("../../../assets/images/dataScreen/ttt.png");
    width: 941px;
    height: 508px;
    border-radius: 30px;
    position: absolute;
    bottom: 70px;
    left: 76px;

}

div.erweimaCls {
    background-image: url("../../../assets/images/dataScreen/erweima.png");
    width: 100%;
    height: 507px;
    background-size: 985px 507px;
    background-repeat: no-repeat;
    background-position: center center;

}

div.boxTitleCls {
    font-size: 30px;
    height: 50px;
    line-height: 50px;
    font-weight: bolder;

}

div.jisuifBgCls {
    margin-top: 40px;
    background: #203F76;
    height: 48px;
    line-height: 48px;
}

div.jisuifBgCls>div:nth-child(1) {
    font-size: 28px;
    float: left;
    text-indent: 25px;
}

div.jisuifBgCls>div:nth-child(2) {
    font-size: 35px;
    float: right;
    padding-right: 30px;
    color: #23D1FF;
}

div.jisuifBgCls>div:nth-child(2)>span {
    font-size: 27px;
    margin-left: 25px;
    color: #fff;
}

div.jisuif2BgCls {
    margin-top: 40px;
    background: #203F76;
    height: 48px;
    line-height: 48px;
}

div.jisuif2BgCls>div:nth-child(1) {
    font-size: 28px;
    float: left;
    text-indent: 25px;
    width: 150px;
}

div.jisuif2BgCls>div:nth-child(2) {
    font-size: 24px;
    float: left;
    padding-right: 0px;
    color: #FED300;
    width: 140px;
}

div.jisuif2BgCls>div:nth-child(3) {
    font-size: 24px;
    float: left;
    padding-right: 10px;
    color: #Fff;
}

div.jisuif2BgCls>div:nth-child(4) {
    font-size: 24px;
    float: right;
    padding-right: 20px;
    color: #FED300;
}


div.boxTitleCls>img {
    vertical-align: middle;
    height: 23px;
    width: 93px;
    margin-left: 20px;
}

div.shujuTableCls {
    height: 125px;
    margin-top: 50px;
    position: relative;
}

div.shujuTableCls>div.splitCls {
    width: 5px;
    height: 62px;
    background: #23D1FF;
    opacity: 0.5;
    float: left;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 50px;
}

div.jdsjCls {
    width: 235px;
    height: 100px;
    font-size: 30px;
    float: left;
    color: #fff;

}

div.jdsjCls>div:nth-child(1) {
    text-align: center;
}

div.jdsjCls>div:nth-child(2) {
    text-align: center;
    color: #00B7EE;
    font-weight: bolder;
    font-size: 40px;
    padding-top: 20px;
}

div.jdsjCls>div:nth-child(2)>span {
    color: #fff;
    font-weight: normal;
    font-size: 30px;
}

div.btfusCls {
    padding-top: 30px;
    height: 400px;
}

div.zzmjBoxCls {
    float: left;
    width: 420px;
    height: 200px;
}

div.yugunianzongliangBoxCls {
    float: left;
    width: 480px;
    height: 200px;
    margin-left: 40px;
}

div.vedioCls {
    width: 439px;
    height: 365px;
    background: #0E1639;
    border: 5px solid #123171;
    float: left;
    margin-top: 30px;
    margin-right: 40px;
    margin-bottom: 20px;
    position: relative;
}

div.vedioCls:nth-child(2) {
    margin-left: 20px;
    margin-right: 20px;
}

div.vedioCls>div.vedioTitleCls {
    background-image: url("../../../assets/images/dataScreen/shipinjuxCsdiB.png");
    background-size: 439px 103px;
    background-repeat: no-repeat;
    background-position: center right;
    position: absolute;
    font-size: 30px;
    color: #fff;
    bottom: -10px;
    right: 0px;
    width: 100%;
    height: 103px;
    line-height: 103px;
    text-align: right;
    padding-right: 20px;
}

.guwuzaliangzhongdituCls {
   /* background-image: url("../../../assets/images/dataScreen/guwuzaliangzhongditu.png");
    background-size: cover;*/
    width: 910px;
    height: 1370px;

}

div.qixiangzhanCls {
    height: 350px;
    margin-top: 35px;
}

div.zbsjiyeuCls {
    display: flex;
    height: 150px;
}

div.zbsjiyeuCls>div {
    float: left;
    font-size: 22px;
    flex: 1;
    background-repeat: no-repeat;
    height: 150px;
    text-align: center;
    padding-top: 125px;
    background-position: center top;

}

div.zbsjiyeuCls>div.qixiangzbsjCls1 {
    background-image: url("../../../assets/images/dataScreen/qixiangzbsj1.png");
    background-size: 140px 105px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls2 {
    background-image: url("../../../assets/images/dataScreen/qixiangzbsj2.png");
    background-size: 140px 105px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls3 {
    background-image: url("../../../assets/images/dataScreen/qixiangzbsj3.png");
    background-size: 140px 105px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls4 {
    background-image: url("../../../assets/images/dataScreen/qixiangzbsj4.png");
    background-size: 140px 105px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls5 {
    background-image: url("../../../assets/images/dataScreen/qixiangzbsj5.png");
    background-size: 140px 105px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls6 {
    background-image: url("../../../assets/images/dataScreen/qixiangzbsj6.png");
    background-size: 140px 105px;
}

div.zbsjiyeuDataCls {
    width: 100%;
    height: 75px;
    line-height: 75px;
    font-size: 22px;
    display: flex;
}

div.zbsjiyeuDataCls>div {
    float: left;
    flex: 1;
    text-align: center;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls1 {
    color: #A4DF11;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls2 {

    color: #F7E706;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls3 {

    color: #00FB40;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls4 {

    color: #EFBD01;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls5 {

    color: #02FFFF;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls6 {

    color: #CD48FD;
}

div.wendubiaoCls {
    background-image: url("../../../assets/images/dataScreen/wendubiao.png");
    width: 320px;
    height: 320px;
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
    line-height: 280px;
    background-size: cover;
    float: left;
}

div.wuliansbCls {
    width: 600px;
    height: 100%;
    float: left;
    padding-left: 100px;
}

div.wuliansbCls>div.wlsbRowCls {
    height: 55px;
}

div.wuliansbCls>div.wlsbRowCls>div {
    background-repeat: no-repeat;
    font-size: 25px;
    color: #00E620;
}

div.wlsb1IconCls {
    background-image: url("../../../assets/images/dataScreen/wlsb1.png");
    background-size: 36px 32px;
    background-position: 0px 10px;
    float: left;
    text-indent: 50px;
}

div.wlsb2IconCls {
    background-image: url("../../../assets/images/dataScreen/wlsb2.png");
    background-size: 36px 32px;
    background-position: 35px 0px;
    float: left;
    text-indent: 85px;
}

div.wlsb3IconCls {
    background-image: url("../../../assets/images/dataScreen/wlsb3.png");
    background-size: 36px 32px;
    background-position: 70px 0px;
    float: left;
    text-indent: 120px;
}

div.wlsb4IconCls {
    background-image: url("../../../assets/images/dataScreen/wlsb7.png");
    background-size: 36px 32px;
    background-position: 70px 0px;
    float: left;
    text-indent: 120px;
}

div.wlsb5IconCls {
    background-image: url("../../../assets/images/dataScreen/wlsb5.png");
    background-size: 36px 32px;
    background-position: 35px 0px;
    float: left;
    text-indent: 100px;
}

div.wlsb6IconCls {
    background-image: url("../../../assets/images/dataScreen/wlsb6.png");
    background-size: 36px 32px;
    background-position: 0px 10px;
    float: left;
    text-indent: 50px;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2) {
    float: right;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span.status1Cls {
    background-color: #00E620;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span.status2Cls {
    background-color: #f00;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span:nth-child(1) {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 30px;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span:nth-child(2) {
    color: #fff;
    font-size: 28px;
    display: inline-block;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span:nth-child(3) {
    color: #fff;
    display: inline-block;
    margin-left: 20px;
    font-size: 24px;
}

div.zuihouCls {
    width: 100%;
    height: 300px;
    margin-top: 50px;
}

div.zuihouCls>div.zuihLeftCls {
    float: left;
    width: 60%;
    height: 100%;
}

div.ysltjBoxCls {

    height: 200px;
    line-height: 200px;
    padding-left: 90px;
    background-image: url("../../../assets/images/dataScreen/ysltj.png");
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position: left center;
}

div.ysltjBoxCls>div,
div.yfltjBoxCls>div {
    float: left;
    font-size: 46px;
}

div.ysltjBoxCls>div:nth-child(1) {
    width: 280px;
    color: #00E620;
}

div.ysltjBoxCls>div:nth-child(2),
div.yfltjBoxCls>div:nth-child(2) {
    width: 280px;
}

div.ysltjBoxCls>div:nth-child(3),
div.yfltjBoxCls>div:nth-child(3) {
    width: 40px;
}

div.ysltjBoxCls>div:nth-child(4),
div.yfltjBoxCls>div:nth-child(4) {
    width: 120px;
}

div.yfltjBoxCls {

    height: 130px;
    line-height: 130px;
    padding-left: 90px;
    background-image: url("../../../assets/images/dataScreen/yfltj.png");
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position: left center;
}

div.yfltjBoxCls>div:nth-child(1) {
    width: 280px;
    color: #A4DF11;
}

div.zuihouCls>div.zuihRightCls {
    float: left;
    width: 100%;
    height: 100%;
}

div.sysdgBoxCls {
    font-size: 46px;
    height: 200px;
    line-height: 200px;
    padding-left: 90px;
    background-image: url("../../../assets/images/dataScreen/baojin.png");
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position: left center;
}

div.sysdgBoxCls>div:nth-child(1),
div.sysdg2BoxCls>div:nth-child(1) {
    float: left;
}

div.sysdgBoxCls>div:nth-child(2),
div.sysdg2BoxCls>div:nth-child(2) {
    float: right;
}

div.sysdg2BoxCls {
    font-size: 46px;
    height: 130px;
    line-height: 130px;
    padding-left: 90px;
    background-image: url("../../../assets/images/dataScreen/baojin.png");
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position: left center;
}

div.rizhiCls {
    width: 100%;
    height: 2400px;
    margin-top: 80px;
}

div.rizhiCls>div:nth-child(1) {
    float: left;
    background-image: url("../../../assets/images/dataScreen/gecuy.png");
    background-size: cover;
    width: 37px;
    height: 2375px;
    margin-top: 40px;

}

div.rizhiCls>div:nth-child(2) {
    float: left;
}

div.rizhiBoxCls {
    background-image: url("../../../assets/images/dataScreen/duihuabox.png");
    background-size: cover;
    width: 1252px;
    height: 541px;
    padding: 40px 40px 40px 70px;
}

div.rizhizhutuCls {
    height: 100px;
    line-height: 70px;
    border-bottom: 1px solid #08578c;

}

div.rizhizhutuCls>div {
    float: left;
    font-size: 42px;
}

div.rizhizhutuCls>div:nth-child(1) {
    width: 400px;
}

div.rizhizhutuCls>div:nth-child(2) {
    width: 450px;
    background-image: url("../../../assets/images/dataScreen/yuanjiaosplit.png");
    background-size: 12px 39px;
    background-repeat: no-repeat;
    background-position: left 18px;
    height: 100%;
    text-indent: 30px;
}

div.rizhizhutuCls>div:nth-child(3) {
    background-image: url("../../../assets/images/dataScreen/yuanjiaosplit.png");
    background-size: 12px 39px;
    background-repeat: no-repeat;
    background-position: left 18px;
    height: 100%;
    text-indent: 30px;
}

div.rizhizhaopianCls {
    width: 100%;
    height: 320px;
    margin-top: 40px;
    display: flex;
}

div.rizhizhaopianCls>div:nth-child(1) {
    float: left;
    margin-right: 40px;
    flex: 1;
}

div.rizhizhaopianCls>div:nth-child(2) {
    float: left;
    flex: 1;

}

div.rizhizhaopianCls img {
    width: 100%;
    height: 100%;
}

div.yishengxiaomiCls {
    height: 80px;
    font-size: 50px;
    font-weight: 400;
    color: #00B7EE;
    line-height: 72px;
    margin-top: 100px;
    background-image: url("../../../assets/images/dataScreen/sanniuao.png");
    background-repeat: no-repeat;
    background-size: 41px 43px;
    background-position: center left;
    text-indent: 60px;
}

div.yishengxiaomiJieshaoCls {
    text-indent: 80px;
    line-height: 80px;
    font-size: 40px;
    font-weight: 400;
    color: #00B7EE;
    text-align: justify;
    text-justify: distribute-all-lines;
}

div.orderTableCls {
    font-size: 40px;
    padding-top: 60px;
    padding-bottom: 60px;
}

div.orderTableCls>div.ysmiHeadCls {
    color: #00b7b6;
}

div.orderTableCls>div.ysmiHeadCls>div {
    float: left;
}

div.orderTableCls>div>div:nth-child(1) {
    width: 300px;
    text-indent: 50px;
}

div.orderTableCls>div>div:nth-child(2) {
    width: 400px;
}

div.orderTableCls>div>div:nth-child(3) {
    width: 400px;
}

div.orderTableCls>div>div:nth-child(4) {
    width: 300px;
}

div.orderTableCls>div {
    border-bottom: 1px solid #04496e;
    height: 131px;
    line-height: 131px;
}

div.orderTableCls>div>div {
    float: left;
}

div.orderTableCls>div:nth-child(2n) {
    color: #00a5d9;
}

div.orderTableCls>div:nth-child(2n+1) {
    color: #01d3cc;
}
div.zsboxCls{
    width: 100%;
    height: 559px;
    margin-top: 80px;
    display: flex;
}
div.zsboxCls>div:nth-child(1){
    flex:1;
    margin-right: 20px;
    background-image: url("../../../assets/images/dataScreen/zsBox.png");
    background-size: 736px 559px;
}
div.zsboxCls>div:nth-child(2){
    flex:1;
    margin-left: 20px;
    background-image: url("../../../assets/images/dataScreen/zsBox.png");
    background-size: 736px 559px;
}
div.zsTitleCls{
    font-size: 32px;
    margin-top: -20px;
    text-align: center;
}
div.zsFileCls{
    display: flex;
    height: 500px;
    margin: 40px;
}
div.zsFileCls>div>img{
    width: 100%;
    height: 100%;
}
div.zsFileCls>div:nth-child(1){
    flex: 1;
    background-color: #00E620;
    height: 460px;
    margin-right: 20px;
}
div.zsFileCls>div:nth-child(2){
    flex: 1;
    background-color: #00a5d9;
    height: 460px;
    margin-left: 20px;
}
div.gzbsCls{
    display: flex;
    margin-top: 40px;
    font-size: 28px;
}
div.gzbsCls>div{
    flex: 1;
    height: 200px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center top;
    line-height: 400px;
}
div.gzbsCls>div:nth-child(1){
    background-image: url("../../../assets/images/dataScreen/zzz1.png");
    background-size: 110px 121px;
}
div.gzbsCls>div:nth-child(2){
    background-image: url("../../../assets/images/dataScreen/zzz2.png");
    background-size: 110px 121px;
}
div.gzbsCls>div:nth-child(3){
    line-height: 65px;
  /*  background-image: url("../../../assets/images/dataScreen/zzz3.png");
    background-size: 166px 166px;*/
}
div.gzbsCls>div:nth-child(4){
    background-image: url("../../../assets/images/dataScreen/zzz4.png");
    background-size: 110px 121px;
}
div.gzbsCls>div:nth-child(5){
    background-image: url("../../../assets/images/dataScreen/zzz5.png");
    background-size: 110px 121px;
}
.erCode {
    width: 166px;
    height: 166px;
    display: block;
    margin: 0 auto;
}

.nsyjClsBiaoti{
  width: 100%;
  height: 100px;
  line-height: 100px;
  color: #ffffff;
  font-size: 25px;
  margin-top: 25px;
}
.nsyjClsBiaoti span{
  display: inline-block;
  width: 25%;
  text-align: center;
  background: #112849;
  border-right: 1px solid #123669;
}
.nsyjClsBiaotiCls{
    display: flex;
    padding-left: 15px;
}
.nsyjClsBiaotiCls span{
    display: flex;
    height: 75px;
    width: 25%;
    text-align: center;
    font-size: 25px;
    color: #ffffff;
    /* line-height: 150px; */
    margin-top: 7px;
    align-items: center;
    justify-content: center;
}
</style>