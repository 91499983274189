<template>
  <div>
    <div id="homeActualCount" class="body-weight"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as echarts from "echarts";
export default {
  name: "homeActualCount",
  data() {
    return {
      echartsData: [],
    };
  },
  mounted() {
    //this.actualCount();
  },
  methods: {
    actualCount() {
      console.log(11111111)
      console.log(this.echartsData)
      var chartDom = document.getElementById("homeActualCount");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        // title: {
        //     text: '某站点用户访问来源',
        //     subtext: '纯属虚构',
        //     left: 'center'
        // },
        tooltip: {
          show: true,
          trigger: "item",
          textStyle: {
            color: '#000',
            fontSize: 25
          },
        },
        grid:{
            top: 50,
            left: 220,
            right: 40,
            bottom:  1
        },
        legend: {
          show: false,
          orient: "vertical",
          left: "right",
          textStyle: {
            // fontWeight : 600 ,
            // fontSize : 30,    //文字的字体大小
            color: "#fff",
          },
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["30%", "55%"],
            labelLine: {
              normal: {
                smooth: 1,
                length: 150,
              },
            },

            label: {
              //饼图图形上的文本标签
              normal: {
                show: true,
                //position:'inner', //标签的位置
                textStyle: {
                  fontSize: 25, //文字的字体大小
                  color: "#ccc",
                },
                // formatter:\'{d}%\'
              },
            },
            data: this.echartsData,
            // emphasis: {
            //     itemStyle: {
            //         shadowBlur: 10,
            //         shadowOffsetX: 0,
            //         shadowColor: 'rgba(0, 0, 0, 0.5)'
            //     }
            // }
          },
        ],
        color: ["#F7C650", "#00D6E8", "#7C00FF", "#007AFF"],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped>
.body-weight {
  width: 100%;
  height: 600px;
}
</style>